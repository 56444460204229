<script lang="ts" setup>
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import { computed } from "vue";

import type { FilterConfig } from "@/components/business/ListFilters/types";
import VdInputNumber from "@/components/structures/VdNumberInput/VdInputNumber.vue";
import { FILTERS_MODES, type FiltersModesKeys } from "@/constants";

type NumericalFilterInputContext = {
  format?: FilterConfig["format"];
};

const props = defineProps<{
  value: number | null;
  matchMode: FiltersModesKeys;
  context?: NumericalFilterInputContext;
}>();

const emit = defineEmits<{
  "update:value": [value: number | null];
}>();

const format = computed(() => props.context?.format);

const isPercentValue = computed(() => format.value?.style === "percent");

const prefix = computed(() => format.value?.style === "currency" ? "$" : "");
const suffix = isPercentValue.value ? "%" : "";

function onUpdateValue(value: number | null) {
  if (value == null) {
    emit("update:value", null);
    return;
  }

  const newValue = isPercentValue.value && format.value?.percentMode !== "absolute"
    ? (value as number) / 100
    : (value as number);
  emit("update:value", newValue);
}

const formattedValue = computed(() => {
  if (props.value == null) {
    return null;
  }

  return isPercentValue.value
    && format.value?.percentMode !== "absolute"
    ? (props.value as number) * 100
    : (props.value as number);
});

const matchModeValue = computed(() => FILTERS_MODES.find(mode => mode.value === props.matchMode)?.label ?? null);

const isFloat = computed(() => format.value?.style === "currency" || isPercentValue.value);
</script>

<template>
  <InputGroup
    class="numerical-filter-input-component"
    :class="{ 'p-inputgroup flex-1': !!suffix }"
  >
    <VdInputNumber
      placeholder="Enter value to compare"
      :model-value="formattedValue"
      :prefix="`${matchModeValue} ${prefix}`"
      :pt="{ input: { class: 'text-xs' } }"
      :fraction-digits="isFloat ? 2 : 0"
      allow-empty
      autofocus
      data-test="numerical-filter-input"
      @update:model-value="onUpdateValue"
    />
    <InputGroupAddon v-if="suffix">
      {{ suffix }}
    </InputGroupAddon>
  </InputGroup>
</template>
