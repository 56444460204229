import {
  type GetPresignedUrlResponseItemFragment,
  graphql,
  type PreSignedUrlRequestItem,
  runGraphqlQuery,
} from "@/graphql";

export const query = graphql(/* GraphQL */ `
  query GetPresignedUrl($item: PreSignedUrlRequestItem!) {
    assetUploadUrl(item: $item) {
      presignedId
      identifier
      url
    }
  }
`);

graphql(/* GraphQL */ `
  fragment GetPresignedUrlResponseItem on PreSignedUrlResponseItem {
    presignedId
    identifier
    url
  }
`);

export async function getPresignedUrl(
  item: PreSignedUrlRequestItem,
): Promise<GetPresignedUrlResponseItemFragment | null> {
  const result = await runGraphqlQuery(query, { item });
  return result?.assetUploadUrl ?? null;
}
