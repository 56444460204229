import isEqual from "lodash.isequal";
import { computed, type WritableComputedRef } from "vue";

import { createObjectSerializer, type ObjectSerializerSchema } from "./object-serializer";
import { createUrlStorage } from "./url-storage";

export type { SchemaField } from "./object-serializer";

/**
 * This composable is responsible for managing the component state and synchronizing it
 * with the URL's query parameters. It can be used to store the current state of a component,
 * allowing users to share or bookmark URLs containing the relevant state information.
 *
 * The URL is used to initialize the state. It is then updated to reflect the state changes.
 */
export function useUrlState<T extends object>({
  schema,
  initialValue,
}: {
  schema: ObjectSerializerSchema<T>;
  initialValue: T;
}): WritableComputedRef<T> {
  const serializer = createObjectSerializer<T>(schema);
  const urlStorage = createUrlStorage<T>(Object.keys(schema) as Array<keyof T>);

  const defaultValue = serializer.serialize(initialValue);

  /**
   * The writable computed that will handle the application state
   */
  return computed<T>({
    get() {
      const serializedObject = (urlStorage.get() as { [K in keyof T]: string | string[] | null }) ?? window.structuredClone(defaultValue);
      const obj = serializer.deserialize(serializedObject);
      return Object.keys(initialValue).reduce((result, valueKey) => {
        const fieldName = valueKey as unknown as keyof T;

        /**
         * If the field is null or equal to the initial value, we remove it from the URL
         *
         * If there is no value, it may mean that the value couldn't be decoded by the serializer
         * and that the user put a wrong value in the URL.
         * In this case, we just clean the URL by removing the falsy param.
         */
        if (obj[fieldName] == null || isEqual(serializedObject[fieldName], defaultValue[fieldName])) {
          urlStorage.deleteParam(fieldName);
        }
        return {
          ...result,
          [fieldName]: obj[fieldName] ?? initialValue[fieldName],
        };
      }, {} as T);
    },

    set(newValue) {
      const value = serializer.serialize(newValue);
      if (isEqual(urlStorage.get(), value)) {
        return;
      }

      const urlValue = Object.entries(value).reduce(
        (result, [fieldName, fieldValue]) => ({
          ...result,
          [fieldName]: Array.isArray(fieldValue) && fieldValue.length === 0 ? null : fieldValue,
        }),
        {} as typeof value,
      );
      urlStorage.set(urlValue);
    },
  });
}
