<script lang="ts" setup>
import { computed } from "vue";

import { formatLanguage } from "@/constants/languages";

const props = defineProps<{
  value: string[];
}>();

const languageValue = computed(() => {
  if (props.value.length > 3) {
    return `${props.value.length} languages`;
  }

  return props.value.map(language => formatLanguage(language)).join(", ");
});
</script>

<template>
  <span v-if="value.length > 0" class="vd-language-tag-component">{{ languageValue }}</span>
</template>
