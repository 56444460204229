<script setup lang="ts">
import { tv } from "tailwind-variants";
import { computed } from "vue";

import { STORE_INFOS } from "@/constants";
import { Store } from "@/graphql";
import type { Size } from "@/types/common";

const props = withDefaults(defineProps<{
  url: string;
  store?: Store | null;
  size?: Size;
  tooltipText?: string;
}>(), {
  size: "normal",
  tooltipText: "",
  store: null,
});

const storeInfo = computed(() => {
  if (props.store == null) {
    return null;
  }
  return STORE_INFOS[props.store];
});

const isSmall = computed(() => props.size === "small");
const computedStore = computed(() => props.store ?? undefined);

const defaultSize = {
  xsmall: undefined,
  small: undefined,
  normal: undefined,
  large: undefined,
};

const appIconImageClass = tv({
  base: "app-icon-component__image",
  variants: {
    size: {
      xsmall: "size-5",
      small: "size-7",
      normal: "size-10",
      large: "size-16",
    },
  },
});

const appIconContainerClass = tv({
  base: "app-icon-component__icon-container absolute bottom-0 right-0 flex size-5 items-center justify-center text-white",
  variants: {
    store: {
      [Store.GooglePlay]: "bg-android",
      [Store.AppStore]: "bg-ios",
    },
    size: {
      ...defaultSize,
      large: "size-6 text-sm",
    },
  },
});

const appIconIconClass = tv({
  base: `app-icon-component__icon text-[0.6rem] ${storeInfo.value?.icon}`,
  variants: {
    size: {
      ...defaultSize,
      large: "text-xs",
    },
  },
});
</script>

<template>
  <div class="app-icon-component__image-container relative flex items-center gap-1 leading-none" :class="`app-icon-component__image-container-${size}`">
    <img
      v-tooltip.top="{ value: tooltipText }"
      :class="appIconImageClass({ size })"
      :src="url"
      alt=""
      referrerpolicy="no-referrer"
      loading="lazy"
    >
    <div
      v-if="storeInfo && !isSmall"
      :class="appIconContainerClass({ store: computedStore, size })"
    >
      <i :class="appIconIconClass({ size })" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-icon-component__image {
  border-radius: var(--border-radius);
}

.app-icon-component__icon-container {
  border-radius: var(--border-radius) 0 var(--border-radius) 0;
}
</style>
