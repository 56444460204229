import { FilterMatchMode } from "primevue/api";
import { z } from "zod";

import type { SchemaField } from "@/composables/use-url-state";

function getEncodedValue(value: boolean | null): string | null {
  if (value === null) {
    return null;
  }
  return value ? "true" : "false";
}

function getDecodedValue(value: string | null): boolean | null {
  if (value === null) {
    return null;
  }
  return value === "true";
}

export const booleanField: SchemaField<{ value: boolean | null; matchMode: typeof FilterMatchMode["EQUALS"] }> = {
  field: z.object({
    value: z.boolean().nullable(),
    matchMode: z.enum([FilterMatchMode.EQUALS]),
  }),
  multi: false,
  encode: obj => getEncodedValue(obj.value),
  decode: value => ({ value: getDecodedValue(value), matchMode: FilterMatchMode.EQUALS }),
};
