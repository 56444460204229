/**
 * This file was generated via the command "npx vue-auth0-generate-permissions"
 * Do not make changes to this file directly
 */
import { computed, type ComputedRef } from "vue";

import useAuthentication from "./use-authentication";

const { permissions } = useAuthentication();

interface Authorizations {
  hasGepettoWritePermission: ComputedRef<boolean>;
  hasCampaignTestModeWritePermission: ComputedRef<boolean>;
  hasXnssAbPlatformCampaignManagePermission: ComputedRef<boolean>;
  hasXnssAbPlatformConfigManagePermission: ComputedRef<boolean>;
  hasXnssAbPlatformPinnedConfigManagePermission: ComputedRef<boolean>;
  hasXnssAbPlatformCampaignReadPermission: ComputedRef<boolean>;
  hasGepettoReadPermission: ComputedRef<boolean>;
  hasXnssAbPlatformConfigReadPermission: ComputedRef<boolean>;
  hasXnssAbPlatformPinnedConfigReadPermission: ComputedRef<boolean>;
  hasXnssAbPlatformSchemaReadPermission: ComputedRef<boolean>;
  hasBatchBidderDumpPermission: ComputedRef<boolean>;
  hasBatchCampaignSeatlimiterPermission: ComputedRef<boolean>;
  hasBatchCampaignUpdaterPermission: ComputedRef<boolean>;
  hasBatchCreativesGeneratesPermission: ComputedRef<boolean>;
  hasXnssEditSelfUserPermission: ComputedRef<boolean>;
  hasXnssArchiveCampaignPermission: ComputedRef<boolean>;
  hasXnssListAccountPermission: ComputedRef<boolean>;
  hasXnssManageCampaignPermission: ComputedRef<boolean>;
  hasXnssManagePublisherPermission: ComputedRef<boolean>;
  hasXnssReadAssetPermission: ComputedRef<boolean>;
  hasXnssReadCampaignReportsPermission: ComputedRef<boolean>;
  hasXnssReadCampaignPermission: ComputedRef<boolean>;
  hasXnssReadCreativePermission: ComputedRef<boolean>;
  hasXnssStoreAccountPermission: ComputedRef<boolean>;
  hasXnssWriteAssetPermission: ComputedRef<boolean>;
  hasXnssWriteCampaignPermission: ComputedRef<boolean>;
  hasXnssWriteCreativePermission: ComputedRef<boolean>;
}

const hasPermission = (permission: string): boolean => permissions.value?.includes(permission) || false;

export default function usePermissions(): Authorizations {
  const hasGepettoWritePermission = computed(() => hasPermission("gepetto:write"));
  const hasCampaignTestModeWritePermission = computed(() => hasPermission("campaign:test-mode:write"));
  const hasXnssAbPlatformCampaignManagePermission = computed(() => hasPermission("xnss:ab-platform:campaign:manage"));
  const hasXnssAbPlatformConfigManagePermission = computed(() => hasPermission("xnss:ab-platform:config:manage"));
  const hasXnssAbPlatformPinnedConfigManagePermission = computed(() => hasPermission("xnss:ab-platform:pinned-config:manage"));
  const hasXnssAbPlatformCampaignReadPermission = computed(() => hasPermission("xnss:ab-platform:campaign:read"));
  const hasGepettoReadPermission = computed(() => hasPermission("gepetto:read"));
  const hasXnssAbPlatformConfigReadPermission = computed(() => hasPermission("xnss:ab-platform:config:read"));
  const hasXnssAbPlatformPinnedConfigReadPermission = computed(() => hasPermission("xnss:ab-platform:pinned-config:read"));
  const hasXnssAbPlatformSchemaReadPermission = computed(() => hasPermission("xnss:ab-platform:schema:read"));
  const hasBatchBidderDumpPermission = computed(() => hasPermission("batch:bidder:dump"));
  const hasBatchCampaignSeatlimiterPermission = computed(() => hasPermission("batch:campaign:seatlimiter"));
  const hasBatchCampaignUpdaterPermission = computed(() => hasPermission("batch:campaign:updater"));
  const hasBatchCreativesGeneratesPermission = computed(() => hasPermission("batch:creatives:generates"));
  const hasXnssEditSelfUserPermission = computed(() => hasPermission("xnss:edit_self:user"));
  const hasXnssArchiveCampaignPermission = computed(() => hasPermission("xnss:archive:campaign"));
  const hasXnssListAccountPermission = computed(() => hasPermission("xnss:list:account"));
  const hasXnssManageCampaignPermission = computed(() => hasPermission("xnss:manage:campaign"));
  const hasXnssManagePublisherPermission = computed(() => hasPermission("xnss:manage:publisher"));
  const hasXnssReadAssetPermission = computed(() => hasPermission("xnss:read:asset"));
  const hasXnssReadCampaignReportsPermission = computed(() => hasPermission("xnss:read:campaign-reports"));
  const hasXnssReadCampaignPermission = computed(() => hasPermission("xnss:read:campaign"));
  const hasXnssReadCreativePermission = computed(() => hasPermission("xnss:read:creative"));
  const hasXnssStoreAccountPermission = computed(() => hasPermission("xnss:store:account"));
  const hasXnssWriteAssetPermission = computed(() => hasPermission("xnss:write:asset"));
  const hasXnssWriteCampaignPermission = computed(() => hasPermission("xnss:write:campaign"));
  const hasXnssWriteCreativePermission = computed(() => hasPermission("xnss:write:creative"));

  return {
    hasGepettoWritePermission,
    hasCampaignTestModeWritePermission,
    hasXnssAbPlatformCampaignManagePermission,
    hasXnssAbPlatformConfigManagePermission,
    hasXnssAbPlatformPinnedConfigManagePermission,
    hasXnssAbPlatformCampaignReadPermission,
    hasGepettoReadPermission,
    hasXnssAbPlatformConfigReadPermission,
    hasXnssAbPlatformPinnedConfigReadPermission,
    hasXnssAbPlatformSchemaReadPermission,
    hasBatchBidderDumpPermission,
    hasBatchCampaignSeatlimiterPermission,
    hasBatchCampaignUpdaterPermission,
    hasBatchCreativesGeneratesPermission,
    hasXnssEditSelfUserPermission,
    hasXnssArchiveCampaignPermission,
    hasXnssListAccountPermission,
    hasXnssManageCampaignPermission,
    hasXnssManagePublisherPermission,
    hasXnssReadAssetPermission,
    hasXnssReadCampaignReportsPermission,
    hasXnssReadCampaignPermission,
    hasXnssReadCreativePermission,
    hasXnssStoreAccountPermission,
    hasXnssWriteAssetPermission,
    hasXnssWriteCampaignPermission,
    hasXnssWriteCreativePermission,
  };
}
