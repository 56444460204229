import type { RouteComponent, RouteRecordRaw } from "vue-router";

import links from "./links";

const AssetsListPage = (): RouteComponent => import("@/pages/assets/AssetsList/AssetsListPage.vue");
const AssetsUploadPage = (): RouteComponent => import("@/pages/assets/AssetsUpload/AssetsUploadPage.vue");

export const assetsRoutes: RouteRecordRaw[] = [
  {
    path: ":assetId?",
    name: links.assets.list,
    component: AssetsListPage,
    props: true,
    meta: {
      title: "Assets Library",
    },
    children: [
      {
        path: "upload",
        name: links.assets.upload,
        component: AssetsUploadPage,
        props: true,
        meta: {
          title: "Assets upload",
        },
      },
    ],
  },
];
