<script lang="ts" setup>
import ProgressBar from "primevue/progressbar";

import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import type { CreateAutomaticAssetMutationResult } from "@/graphql";
import type { UseCreateAsset } from "@/pages/assets/AssetsUpload/composables/use-create-asset";
import links from "@/router/links";

defineProps<{
  files: UseCreateAsset<CreateAutomaticAssetMutationResult>[];
  remainingFilesLength: number;
}>();

const emit = defineEmits<{
  toggle: [];
}>();

function onToggleSize() {
  emit("toggle");
}
</script>

<template>
  <VdRouterLink :to="{ name: links.assets.upload }" class="w-full">
    <div v-for="file in files" :key="file.name" class="text-color relative flex w-full items-center gap-4 font-normal">
      <div class="flex-1 truncate">
        {{ file.name }}
      </div>
      <ProgressBar class="w-3" :value="file.uploadProgression" :show-value="false" style="height: 6px" />
    </div>

    <div v-if="remainingFilesLength" class="uploads-watcher-more-button flex w-full justify-end text-xs">
      +{{ remainingFilesLength }} more
    </div>
  </VdRouterLink>

  <div class="uploads-watcher-minify-button absolute right-3 top-1 mt-1">
    <i
      class="fa-solid fa-minus text-color-secondary cursor-pointer text-xs hover:text-primary"
      @click="onToggleSize"
      @keydown="onToggleSize"
    />
  </div>
</template>
