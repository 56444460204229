import { useStorage } from "@vueuse/core";
import { computed, ref, type WritableComputedRef } from "vue";
import { z } from "zod";

import { type SchemaField, useUrlState } from "@/composables/use-url-state";
import { YESTERDAY_DATE } from "@/constants";
import { extractDateFromDatetime } from "@/utils/common";

const startDate = ref<Date>(new Date());
const endDate = ref<Date>(new Date());

const dateSchemaField: SchemaField<Date> = {
  field: z.date(),
  multi: false,
  encode: (value) => {
    if (value != null) {
      const encodedValue = extractDateFromDatetime(value);
      if (encodedValue !== extractDateFromDatetime(YESTERDAY_DATE)) {
        return encodedValue;
      }
    }
    return null;
  },
  decode: value => (value == null ? YESTERDAY_DATE : new Date(value)),
};

function isYesterdayDate(date: Date): boolean {
  return extractDateFromDatetime(date) === extractDateFromDatetime(YESTERDAY_DATE);
}

export type ReportingDateRange = WritableComputedRef<{ start: Date; end: Date }>;

export default function useReportingDateRange(): ReportingDateRange {
  const storage = useStorage<{ start: string; end: string }>("reporting-date", {
    start: extractDateFromDatetime(YESTERDAY_DATE),
    end: extractDateFromDatetime(YESTERDAY_DATE),
  });

  const state = useUrlState({
    initialValue: {
      start: YESTERDAY_DATE,
      end: YESTERDAY_DATE,
    },
    schema: {
      start: dateSchemaField,
      end: dateSchemaField,
    },
  });

  startDate.value = isYesterdayDate(state.value.start) ? new Date(storage.value.start) : state.value.start;
  endDate.value = isYesterdayDate(state.value.end) ? new Date(storage.value.end) : state.value.end;

  state.value = {
    start: startDate.value,
    end: endDate.value,
  };

  /**
   * The writable computed that will be filled by the primevue calendar component
   */
  return computed<{ start: Date; end: Date }>({
    get() {
      return { start: startDate.value, end: endDate.value };
    },

    set(newValue) {
      const { start, end } = newValue;
      startDate.value = start;
      endDate.value = end;
      storage.value = {
        start: extractDateFromDatetime(start),
        end: extractDateFromDatetime(end),
      };

      state.value = {
        start: startDate.value,
        end: endDate.value,
      };
    },
  });
}
