import { z } from "zod";

export type SchemaField<T> = {
  // eslint-disable-next-line ts/no-explicit-any
  field: z.ZodType<T, any, any>;
} & (
  | {
    multi: false;
    encode: (obj: T) => string | null;
    decode: (value: string) => T | null;
  }
  | {
    multi: true;
    encode: (obj: T) => string[] | null;
    decode: (value: string[]) => T | null;
  }
);

export type ObjectSerializerSchema<T> = {
  [K in keyof Partial<T>]: SchemaField<T[K]>;
};

export function createObjectSerializer<T extends object>(
  schema: ObjectSerializerSchema<T>,
) {
  type SerializedObject = { [K in keyof T]: string | string[] | null };

  return {
    serialize: (obj: T): SerializedObject =>
      Object.entries(schema).reduce((result, [key, sf]) => {
        if (!Object.keys(obj).includes(key)) {
          return result;
        }
        const fieldName = key as keyof T;
        const value = obj[fieldName];
        const schemaField = sf as ObjectSerializerSchema<T>[keyof T];

        if (schemaField == null) {
          throw new Error(`No schema field found for ${String(fieldName)}`);
        }

        const parsedValue = schemaField.field.safeParse(value);
        if (parsedValue.success === false) {
          console.warn("serialize", fieldName, parsedValue.error);
          return { ...result, [fieldName]: null };
        }

        const zodReturnSchema = schemaField.multi
          ? z.array(z.string())
          : z.string();

        let encodedValue: string | string[] | null;

        try {
          encodedValue = schemaField.encode(parsedValue.data);
        }
        catch (e) {
          console.warn("serialize", fieldName, e);
          return { ...result, [fieldName]: null };
        }

        if (encodedValue === null) {
          return { ...result, [fieldName]: null };
        }

        const parsedEncodedResult = zodReturnSchema.safeParse(encodedValue);
        if (parsedEncodedResult.success === false) {
          console.warn("serialize", fieldName, parsedEncodedResult.error);
          return { ...result, [fieldName]: null };
        }

        return {
          ...result,
          [fieldName]: parsedEncodedResult.success
            ? parsedEncodedResult.data
            : null,
        };
      }, {} as SerializedObject),

    deserialize: (serializedObj: SerializedObject): T =>
      Object.entries(serializedObj).reduce((result, [key, encodedValue]) => {
        const fieldName = key as keyof T;
        const schemaField = schema[fieldName];

        if (schemaField == null) {
          throw new Error(`No schema field found for ${String(fieldName)}`);
        }

        const encodedValueSchema = schemaField.multi
          ? z.array(z.string())
          : z.string();

        const sanitizedEncodedValue = schemaField.multi && typeof encodedValue === "string"
          ? [encodedValue]
          : encodedValue;

        const parsedEncodedValue = encodedValueSchema.safeParse(
          sanitizedEncodedValue,
        );
        if (parsedEncodedValue.success === false) {
          return { ...result, [fieldName]: null };
        }

        let decodedValue: T[keyof T] | null;
        try {
          if (schemaField.multi) {
            decodedValue = schemaField.decode(
              parsedEncodedValue.data as string[],
            );
          }
          else {
            decodedValue = schemaField.decode(
              parsedEncodedValue.data as string,
            );
          }
        }
        catch (e) {
          console.warn(
            "deserialize",
            fieldName,
            "==>",
            parsedEncodedValue.data,
            "<===",
            e,
          );
          return { ...result, [fieldName]: null };
        }

        const parsedDecodedResult = schemaField.field
          .nullish()
          .safeParse(decodedValue);
        if (parsedDecodedResult.success === false) {
          console.warn(
            "deserialize",
            fieldName,
            "--->",
            decodedValue,
            "<---",
            parsedDecodedResult.error,
          );
          return { ...result, [fieldName]: null };
        }

        return { ...result, [fieldName]: parsedDecodedResult.data };
      }, {} as T),
  };
}
