import { DeviceType } from "@/graphql";

export function prettifyDeviceType(deviceType: DeviceType): string {
  const mapping: Record<DeviceType, string> = {
    [DeviceType.MobileOrTablet]: "All",
    [DeviceType.Phone]: "Phone",
    [DeviceType.Tablet]: "Tablet",
  };
  return mapping[deviceType];
}
