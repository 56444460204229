<script lang="ts" setup>
import { computed } from "vue";

import { PLATFORM_INFOS } from "@/constants";
import { Platform } from "@/graphql";

const props = withDefaults(defineProps<{
  platform: Platform;
  withTooltip?: boolean;
  unstyled?: boolean;
}>(), {
  withTooltip: false,
  unstyled: false,
});

const platformInfo = computed(() => PLATFORM_INFOS[props.platform]);

const iconColor = computed(() => {
  if (platformInfo.value == null) {
    return "";
  }
  switch (platformInfo.value.id) {
    case Platform.Android:
      return "text-android";
    case Platform.Ios:
      return "text-ios";
    default:
      return "";
  }
});
</script>

<template>
  <i
    v-tooltip.top="{ value: withTooltip ? platformInfo.label : null }"
    class="platform-icon-component inline"
    :class="[
      platformInfo.icon,
      unstyled ? '' : iconColor,
    ]"
  />
</template>
