<script setup lang="ts">
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Skeleton from "primevue/skeleton";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    columns: { id: string; label?: string }[];
    nbRows?: number;
  }>(),
  {
    nbRows: 10,
  },
);

const data = computed(() => {
  const columnKeys = Object.fromEntries(props.columns.map(({ id }) => [id, 0]));
  return Array.from({ length: props.nbRows }, () => columnKeys);
});
</script>

<template>
  <DataTable class="vd-table-skeleton-component" :value="data" responsive-layout="scroll">
    <Column v-for="column in columns" :key="column.id" :field="column.id" :header="column.label">
      <template #body>
        <Skeleton height="2rem" />
      </template>
    </Column>
  </DataTable>
</template>
