<script setup lang="ts">
import { computed } from "vue";

import { getCountryAlpha2Code } from "@/constants/countries";

const props = defineProps<{
  code: string;
  isCircle?: boolean;
}>();

const alpha2Code = computed(() => {
  if (props.code.length === 2) {
    return props.code.toLowerCase();
  }
  return getCountryAlpha2Code(props.code.toUpperCase())?.toUpperCase() ?? "";
});
</script>

<template>
  <div
    class="vd-country-icon relative flex items-center justify-center overflow-hidden"
    :class="{ 'size-[25px] rounded-full': isCircle, 'h-4 w-5 rounded-sm': !isCircle }"
  >
    <img
      class="block"
      :class="{ 'size-full object-cover object-center': isCircle }"
      :src="`/flags/${alpha2Code}.svg`"
      loading="lazy"
    >
  </div>
</template>

<style lang="scss" scoped>
.vd-country-icon {
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.1);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    mix-blend-mode: overlay;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 2%, rgba(255, 255, 255, 0.70) 100%);
  }
}
</style>
