<script setup lang="ts">
import Button from "primevue/button";
import { useRouter } from "vue-router";

import links from "@/router/links";

import astronautUrl from "./assets/dwarf.png";

withDefaults(
  defineProps<{
    text?: string;
  }>(),
  {
    text: "The content you are looking for has not been found",
  },
);

const router = useRouter();

function onGoBackHome() {
  router.push({ name: links.home });
}
</script>

<template>
  <div class="not-found__container flex w-full flex-col justify-center pb-4 text-center">
    <div class="not-found__box text-color flex flex-wrap items-center justify-center gap-8">
      <section class="not-found z-10">
        <div class="flex flex-col items-center gap-8">
          <div>
            <h1 class="m-0 text-[14rem] leading-none">
              404
            </h1>
            <h2 class="m-0 text-5xl leading-[5rem]">
              Ooops...
            </h2>
            <span class="text-color-secondary text-base uppercase">{{ text }}</span>
          </div>

          <Button
            class="vd-not-found__button"
            label="Back to homepage"
            icon="fa-light fa-arrow-left"
            outlined
            @click="onGoBackHome"
          />
        </div>
      </section>
      <img :src="astronautUrl" alt="Not found" class="not-found__image z-0 w-full max-w-xl">
    </div>
  </div>
</template>
