<script lang="ts" setup>
import PlatformIcon from "@/components/business/PlatformIcon/PlatformIcon.vue";
import VdSelectableListBox from "@/components/structures/VdSelectableListBox/VdSelectableListBox.vue";
import { PLATFORM_INFOS } from "@/constants";
import type { Platform } from "@/graphql";

defineProps<{
  value: Platform[];
}>();

const emit = defineEmits<{
  "update:value": [value: string[] | null ];
}>();

function onUpdate(value: string[] | string | null) {
  if (value == null) {
    emit("update:value", null);
    return;
  }
  const platform = Array.isArray(value) ? value : [value];
  emit("update:value", platform);
}

const options = Object.values(PLATFORM_INFOS).map(o => ({ label: o.label, value: o.id }));
</script>

<template>
  <VdSelectableListBox
    class="platforms-filter-input-component"
    :value="value"
    :options="options"
    option-label="label"
    option-value="value"
    multiple
    @update:value="onUpdate"
  >
    <template #option="slotProps">
      <span>
        <PlatformIcon
          :platform="slotProps.option.value"
          data-test="platform-filter-option"
        />
        {{ slotProps.option.label }}
      </span>
    </template>
  </VdSelectableListBox>
</template>
