<script setup lang="ts">
import { ref } from "vue";

import type { DateRangeEmittedValue } from "@/components/structures/VdDateRangeCalendar/types";
import VdDateRangeCalendar from "@/components/structures/VdDateRangeCalendar/VdDateRangeCalendar.vue";
import useReportingDateRange from "@/composables/use-reporting-daterange";
import { YESTERDAY_DATE } from "@/constants";

const emit = defineEmits<{
  select: [{ startDate: string; endDate: string }];
}>();

const reportingDateRange = useReportingDateRange();

const model = ref<[Date, Date]>([
  reportingDateRange.value.start ?? YESTERDAY_DATE,
  reportingDateRange.value.end ?? YESTERDAY_DATE,
]);

function onSelect(value: DateRangeEmittedValue) {
  const { startDate, endDate } = value;

  /* We set the endDate to startDate if not defined */
  const newValue = {
    startDate,
    endDate: endDate || startDate,
  };

  reportingDateRange.value = {
    start: new Date(startDate),
    end: new Date(newValue.endDate),
  };

  emit("select", newValue);
}
</script>

<template>
  <VdDateRangeCalendar v-model="model" tooltip-text="Statistics dates range" @select="onSelect" />
</template>
