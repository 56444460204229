export function roundValueWithPrecision(value: number, precision: number): number {
  const pow = 10 ** precision;

  return Math.round(value * pow) / pow;
}

export function prettifyCurrency(value: number | null | undefined, options?: Intl.NumberFormatOptions): string {
  if (value == null) {
    return "$0.00";
  }

  const maximumFractionDigits = options?.maximumFractionDigits ?? 2;

  const roundedValue = roundValueWithPrecision(value, maximumFractionDigits);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
  }).format(Number.parseFloat(`${roundedValue}`));
}
