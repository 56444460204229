import { AssetStatus } from "@/graphql";

export function prettifyAssetStatus(assetStatus: AssetStatus): string {
  const mapping: Record<AssetStatus, string> = {
    [AssetStatus.Archived]: "Archived",
    [AssetStatus.Active]: "Active",
    [AssetStatus.Draft]: "Draft",
    [AssetStatus.Failed]: "Failed",
    [AssetStatus.Pending]: "Encoding",
  };
  return mapping[assetStatus];
}
