import type { Ref } from "vue";

import {
  type BrandingCampaignsListPageReportsQueryVariables,
  buildQuery,
  buildQueryKey,
  graphql,
  runGraphqlQuery,
} from "@/graphql";

const query = graphql(/* GraphQL */ `
  query BrandingCampaignsListPageReports($startDate: String!, $endDate: String!, $today: String!) {
    brandingCampaigns(first: 10000, reportStartDate: $startDate, reportEndDate: $endDate, reportType: PROBABILISTIC) {
      edges {
        node {
          campaign {
            id
          }
          report {
            ...brandingReport
          }
        }
      }
    }
    todaySpentBudgetByCampaignIds: brandingCampaigns(
      first: 10000,
      reportStartDate: $today
      reportEndDate: $today
      reportType: PROBABILISTIC
    ) {
      edges {
        node {
          campaign {
            id
          }
          report {
            billableSpend
          }
        }
      }
    }
  }
`);

function getQueryOptions(variables: Ref<BrandingCampaignsListPageReportsQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, variables.value);
    },
  };
}

export function useBrandingCampaignsListPageReportsQuery(
  variables: Ref<BrandingCampaignsListPageReportsQueryVariables>,
  { enabled }: { enabled: Ref<boolean> },
) {
  return buildQuery({
    ...getQueryOptions(variables),
    enabled,
  });
}
