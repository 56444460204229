<script setup lang="ts">
import { computed } from "vue";

import VdCountryIcon from "@/components/structures/VdCountryIcon/VdCountryIcon.vue";
import { getCountryName } from "@/constants/countries";

const props = withDefaults(
  defineProps<{
    code: string;
    showLabel?: boolean;
    showTooltip?: boolean;
  }>(),
  {
    showLabel: true,
  },
);

const countryName = computed(() => getCountryName(props.code));
</script>

<template>
  <span v-tooltip.top="{ value: countryName, disabled: !showTooltip }" class="vd-country-chip-component flex items-center gap-2">
    <VdCountryIcon :code="code" class="vd-country-chip-component__icon shrink-0" />
    <span v-if="showLabel" class="truncate">{{ countryName }}</span>
  </span>
</template>
