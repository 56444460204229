import type { RouteComponent, RouteRecordRaw } from "vue-router";

import BrandingCampaignsListPage from "@/pages/branding-campaigns/BrandingCampaignsList/BrandingCampaignsListPage.vue";

import links from "./links";

function NativeAdCreationPage(): RouteComponent {
  return import("@/pages/ads/NativeAdCreation/NativeAdCreationPage.vue");
}

// const BrandingCampaignsListPage = (): RouteComponent =>
//   import("@/pages/branding-campaigns/BrandingCampaignsList/BrandingCampaignsListPage.vue");

function BrandingCampaignCreationPage(): RouteComponent {
  return import(
    "@/pages/branding-campaigns/BrandingCampaignCreation/BrandingCampaignCreationPage.vue"
  );
}

function BrandingCampaignDetailsAdsPage(): RouteComponent {
  return import(
    "@/pages/branding-campaigns/branding-campaign-details/BrandingCampaignDetailsAds/BrandingCampaignDetailsAdsPage.vue"
  );
}

// function BrandingCampaignDetailsCountriesPage(): RouteComponent {
//   return import(
//     "@/pages/branding-campaigns/branding-campaign-details/BrandingCampaignDetailsCountries/BrandingCampaignDetailsCountriesPage.vue"
//   );
// }

function BrandingCampaignDetailsPage(): RouteComponent {
  return import(
    "@/pages/branding-campaigns/branding-campaign-details/BrandingCampaignDetailsPage.vue"
  );
}

// const CampaignDetailsSourcesPage = (): RouteComponent =>
//   import("@/pages/campaigns/campaign-details/CampaignDetailsSources/CampaignDetailsSourcesPage.vue");

function BrandingCampaignEditPage(): RouteComponent {
  return import(
    "@/pages/branding-campaigns/BrandingCampaignEdit/BrandingCampaignEditPage.vue"
  );
}

export const brandingCampaignRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: BrandingCampaignsListPage,
    name: links.brandingCampaigns.list,
    props: true,
    meta: {
      title: "Branding campaigns",
    },
  },
  {
    path: "new",
    component: BrandingCampaignCreationPage,
    props: true,
    name: links.brandingCampaigns.new,
    meta: {
      title: "New branding campaign",
    },
  },
  {
    path: ":duplicateCampaignId/duplicate",
    component: BrandingCampaignCreationPage,
    props: true,
    name: links.brandingCampaigns.duplicate,
    meta: {
      title: "Duplicate the branding campaign",
    },
  },
  {
    path: ":campaignId",
    redirect: { name: links.brandingCampaigns.details.ads },
    props: true,
    children: [
      {
        path: "edit",
        component: BrandingCampaignEditPage,
        name: links.brandingCampaigns.edit,
        props: true,
        meta: {
          title: "Brandingcampaign edition",
        },
      },
      {
        path: "ads/new",
        component: NativeAdCreationPage,
        name: links.brandingCampaigns.ads.newNativeAd,
        props: true,
        meta: {
          title: "New ad",
        },
      },
      {
        path: "details",
        component: BrandingCampaignDetailsPage,
        props: true,
        children: [
          // {
          //   path: "countries",
          //   component: BrandingCampaignDetailsCountriesPage,
          //   name: links.brandingCampaigns.details.countries,
          //   props: true,
          //   meta: {
          //     title: "Branding campaign countries",
          //   },
          // },
          {
            path: "ads/:adId?",
            component: BrandingCampaignDetailsAdsPage,
            name: links.brandingCampaigns.details.ads,
            props: true,
            meta: {
              title: "Branding campaign ADs",
            },
          },
        ],
      },
    ],
  },
];
