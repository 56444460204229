<script lang="ts" setup>
import VdSelectableListBox from "@/components/structures/VdSelectableListBox/VdSelectableListBox.vue";

defineProps<{
  value: string[] | string;

  context: { groupOptions: { code: string; name: string }[] };
}>();

const emit = defineEmits<{
  "update:value": [value: string[] | string | null];
}>();

function onUpdate(value: string[] | string | null) {
  emit("update:value", value);
}
</script>

<template>
  <VdSelectableListBox
    class="ad-set-filter-input-component"
    :value="value"
    :options="context.groupOptions"
    option-label="name"
    option-value="code"
    multiple
    @update:value="onUpdate"
  >
    <template #option="slotProps">
      {{ slotProps.option.name }}
    </template>
  </VdSelectableListBox>
</template>
