<script setup lang="ts">
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { computed } from "vue";

import VdDebouncedInput from "@/components/structures/VdDebouncedInput/VdDebouncedInput.vue";

const props = defineProps<{
  value: string | null;
}>();

const emit = defineEmits<{
  "update:value": [value: string];
}>();

const searchValue = computed(() => props.value);

function onUpdateFilter(value: string) {
  emit("update:value", value);
}
</script>

<template>
  <div class="global-search-filter-input-component flex">
    <div class="relative flex items-center">
      <IconField icon-position="left">
        <InputIcon class="pi pi-search" />
        <VdDebouncedInput
          :model-value="searchValue ?? undefined"
          placeholder="Search..."
          class="w-full p-2 pl-9"
          :class="searchValue ? 'border-primary' : ''"
          :pt="{ root: { class: ' pr-8' } }"
          @update:model-value="onUpdateFilter"
        />
      </IconField>

      <i
        v-if="searchValue"
        class="global-search-filter-clear-button fa-light fa-times absolute right-4 cursor-pointer text-gray-400 hover:text-gray-600"
        @click="() => onUpdateFilter('')"
        @keydown="() => onUpdateFilter('')"
      />
    </div>
  </div>
</template>
