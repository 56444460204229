<script lang="ts" setup>
import AppChip from "@/components/business/AppChip/AppChip.vue";
import VdSelectableListBox from "@/components/structures/VdSelectableListBox/VdSelectableListBox.vue";
import type { AppFragment } from "@/graphql";

type AppFilterInputContext = {
  appOptions: AppFragment[];
};

defineProps<{
  value: string[];
  context: AppFilterInputContext;
}>();

const emit = defineEmits<{
  "update:value": [value: string | string[] | null];
}>();

function onUpdate(value: string | string[] | null) {
  emit("update:value", value);
}
</script>

<template>
  <VdSelectableListBox
    class="app-filter-input-component"
    data-test="app-filter-input-component"
    :value="value"
    :options="context.appOptions"
    option-label="name"
    option-value="id"
    multiple
    filter
    @update:value="onUpdate"
  >
    <template #option="slotProps">
      <AppChip
        size="small"
        :name="slotProps.option.name"
        :icon-url="slotProps.option.iconUrl"
        :store="slotProps.option.store"
      />
    </template>
  </VdSelectableListBox>
</template>
