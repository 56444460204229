import { CampaignStatus } from "@/graphql";

export function prettifyCampaignStatus(status: CampaignStatus): string {
  const mapping: Record<CampaignStatus, string> = {
    [CampaignStatus.Running]: "running",
    [CampaignStatus.Stopped]: "stopped",
    [CampaignStatus.Archived]: "archived",
    [CampaignStatus.Pending]: "pending",
    [CampaignStatus.Draft]: "draft",
  };
  return mapping[status];
}
