<script lang="ts" setup>
import Button from "primevue/button";
import { computed } from "vue";

import DashboardWidget from "@/components/business/DashboardWidget/DashboardWidget.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import { CAMPAIGN_STATUS_ICONS } from "@/constants";
import { CampaignStatus, CampaignType } from "@/graphql";

import { useDashboardStatsQuery } from "../../queries/dashboard-stats.query.graphql";

const props = defineProps<{
  icon: string;
  campaignType: CampaignType;
  title: string;
  newLink: string;
  campaignsListLink: string;
}>();

const { data, isPending } = useDashboardStatsQuery();

const stats = computed<{ [key in CampaignStatus]?: number }>(() => {
  if (data.value == null) {
    return {};
  }

  if (props.campaignType === CampaignType.Performance) {
    return {
      [CampaignStatus.Running]: data.value.performanceRunning.totalCount ?? 0,
      [CampaignStatus.Draft]: data.value.performanceDraft.totalCount ?? 0,
      [CampaignStatus.Stopped]: data.value.performanceStopped.totalCount ?? 0,
    };
  }

  if (props.campaignType === CampaignType.Branding) {
    return {
      [CampaignStatus.Running]: data.value.brandingRunning.totalCount ?? 0,
      [CampaignStatus.Pending]: data.value.brandingPending.totalCount ?? 0,
      [CampaignStatus.Stopped]: data.value.brandingStopped.totalCount ?? 0,
    };
  }

  return {};
});

const statsObject = computed(() => Object.entries(stats.value ?? []).map(([status, value]) => ({
  id: status,
  title: status.toLocaleLowerCase(),
  icon: CAMPAIGN_STATUS_ICONS[status as CampaignStatus],
  value,
})));
</script>

<template>
  <DashboardWidget :loading="isPending">
    <template #title-start>
      <div class="flex items-center gap-3 text-xl font-bold">
        <i :class="icon" />

        <span>{{ title }}</span>
      </div>
    </template>

    <div class="flex w-full flex-col gap-4">
      <div class="flex w-full flex-wrap justify-between gap-3">
        <div class="flex items-center gap-8">
          <VdRouterLink
            v-for="statLine in statsObject"
            :key="statLine.id"
            :to="{
              name: campaignsListLink,
              query: {
                status: statLine.id,
              },
            }"
            class="flex flex-col items-center"
          >
            <div class="flex items-center gap-1 text-xs font-normal">
              <i :class="statLine.icon" />

              <span class="text-base capitalize text-gray-500">{{ statLine.title }}</span>
            </div>

            <span class="text-lg font-bold text-gray-900">{{ statLine.value }}</span>
          </VdRouterLink>
        </div>

        <VdRouterLink
          :to="{
            name: newLink,
          }"
        >
          <Button
            icon="fa-light fa-plus"
            icon-pos="right"
            label="New"
          />
        </VdRouterLink>
      </div>
    </div>
  </DashboardWidget>
</template>
