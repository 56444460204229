<script lang="ts" setup>
import Skeleton from "primevue/skeleton";
import { computed } from "vue";

import { decodeValueForReportingDisplay } from "@/utils/filters/common";

const props = withDefaults(defineProps<{
  value?: number | null;
  format?: Intl.NumberFormatOptions;
  loading?: boolean;
  inError?: boolean;
}>(), {
  value: null,
});

const formattedValue = computed(() => {
  if (props.value == null) {
    return null;
  }
  return Intl.NumberFormat("en-US", props.format).format(decodeValueForReportingDisplay(props.value));
});
</script>

<template>
  <Skeleton v-if="loading" height="2rem" />
  <span v-else-if="inError" v-tooltip.top="'An error occured when loading the statistics'">❌</span>
  <span v-else>{{ formattedValue ?? "-" }}</span>
</template>
