<script setup lang="ts">
import Menu from "primevue/menu";
import type { MenuItem } from "primevue/menuitem";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import VdActionsButton from "@/components/structures/VdActionsButton/VdActionsButton.vue";
import { useCampaignStatus } from "@/composables/campaigns/use-campaign-status";
import { useUpdateCampaignTestModeMutation } from "@/composables/campaigns/use-is-test-mode.mutation.graphql";
import usePermissions from "@/composables/use-permissions";
import { CampaignStatus, useMutationRequest } from "@/graphql";
import links from "@/router/links";

const props = defineProps<{
  campaignId: string;
  campaignStatus: CampaignStatus;
  isTestModeCampaign: boolean;
}>();

const router = useRouter();

const settingsMenu = ref();

const campaignId = computed(() => props.campaignId);
const isArchived = computed(() => props.campaignStatus === CampaignStatus.Archived);
const isPending = computed(() => props.campaignStatus === CampaignStatus.Pending);
const isRunning = computed(() => props.campaignStatus === CampaignStatus.Running);

const { hasCampaignTestModeWritePermission } = usePermissions();

const { startMenuItem, stopMenuItem, archiveMenuItem, isLoading } = useCampaignStatus({ campaignId });
const { mutateAsync: updateTestMode, isPending: testModeLoading } = useUpdateCampaignTestModeMutation();

async function toggleTestMode(value: boolean) {
  const request = () => updateTestMode({ campaignId: props.campaignId, value });
  await useMutationRequest(request);
}

const settingsItems = computed<MenuItem[]>(() => {
  let items: MenuItem[] = [
    {
      label: "Duplicate",
      icon: "fa-regular fa-copy",
      command: () => {
        router.push({ name: links.brandingCampaigns.duplicate, params: { duplicateCampaignId: props.campaignId } });
      },
    },
  ];

  if (isArchived.value === false) {
    items = [
      ...items,
      {
        label: "Edit",
        icon: "fa-regular fa-edit",
        command: () => {
          router.push({ name: links.brandingCampaigns.edit, params: { campaignId: props.campaignId } });
        },
      },
      { separator: true },
      (isRunning.value || isPending.value) ? stopMenuItem : startMenuItem,
      archiveMenuItem,
    ];
  }

  if (hasCampaignTestModeWritePermission.value && isArchived.value === false) {
    items = [
      ...items,
      {
        label: props.isTestModeCampaign ? "Disable test mode" : "Enable test mode",
        icon: props.isTestModeCampaign
          ? "fa-light fa-flask-gear text-red-600"
          : "fa-light fa-flask-gear text-green-600",
        command: () => {
          toggleTestMode(!props.isTestModeCampaign);
        },
      },
    ];
  }

  return items;
});
</script>

<template>
  <div class="campaign-details-menu-component w-full text-center">
    <VdActionsButton
      :loading="isLoading || testModeLoading"
      data-test="campaign-details-menu-button"
      @click="(e) => settingsMenu.toggle(e)"
    />
    <Menu
      ref="settingsMenu"
      class="w-56"
      data-test="campaign-details-menu"
      :model="settingsItems"
      :pt="{ menuitem: { 'data-test': 'campaign-details-menu-item' } }"
      popup
    />
  </div>
</template>
