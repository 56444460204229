import { type Ref, ref } from "vue";

import { getPresignedUrl } from "./get-presigned-url.graphql";

type UseUploadToS3 = {
  url?: string;
  file: File;
};

type UploadReturn = Promise<{
  name: string;
  url: string;
  presignedId: string;
  mimeType: string;
}>;

type UseUploadToS3Return = {
  upload: ({ url, file }: UseUploadToS3) => UploadReturn;
  progression: Ref<number>;
  isLoading: Ref<boolean>;
  isSuccess: Ref<boolean>;
  isError: Ref<boolean>;
};

function useUploadToS3(): UseUploadToS3Return {
  const uploadProgress = ref(0);
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isError = ref(false);

  const upload = async ({ url, file }: UseUploadToS3): UploadReturn => {
    isLoading.value = true;

    let finalUrl = url;
    let presignedId = "";
    const mimeType = file.type;

    if (finalUrl == null) {
      const item = await getPresignedUrl({
        identifier: file.name,
        mimeType: file.type,
      });

      if (!item) {
        isError.value = true;
        throw new Error("Failed to get presigned url");
      }

      finalUrl = item.url;
      presignedId = item.presignedId;
    }

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.onreadystatechange = (): void => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            isSuccess.value = true;
            resolve({
              name: file.name,
              url: finalUrl,
              presignedId,
              mimeType,
            });
          }
          else {
            isError.value = true;
            reject(xhr);
          }
        }
      };

      xhr.upload.onprogress = (e: ProgressEvent): void => {
        if (e.lengthComputable) {
          const percentComplete = (e.loaded / file.size) * 100;
          uploadProgress.value = Number.parseInt(percentComplete.toString(), 10);
        }
      };

      xhr.open("PUT", finalUrl);
      xhr.setRequestHeader("Content-Type", file.type);

      xhr.send(file);
    });
  };

  return { upload, progression: uploadProgress, isLoading, isSuccess, isError };
}

export default useUploadToS3;
