<script lang="ts" setup>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { computed, ref } from "vue";

import DashboardWidget from "@/components/business/DashboardWidget/DashboardWidget.vue";

import { useDashboardRecommendationsQuery } from "../../queries/dashboard-recommendations.query.graphql";
import DashboardRecommendationsWidgetList from "../DashboardRecommendationsWidgetList/DashboardRecommendationsWidgetList.vue";
import type { RecommendationItem } from "./types";

const { data, isPending } = useDashboardRecommendationsQuery();
const isDialogVisible = ref(false);

const recommendations = computed<RecommendationItem[]>(() => {
  if (data.value?.recommendations == null) {
    return [];
  }

  return data.value.recommendations.reduce((acc, recommendation) => {
    const adSet = recommendation.performanceCampaign?.adSets?.find(adSet => adSet.id === recommendation.adSetId);
    const campaign = recommendation.performanceCampaign;

    if (campaign == null) {
      throw new Error("Campaign is not found");
    }

    if (adSet) {
      acc.push({
        id: recommendation.id,
        campaign,
        adSet,
        app: recommendation.performanceCampaign?.app,
        creationDate: new Date(recommendation.creationDate),
      });
    }

    return acc;
  }, [] as RecommendationItem[]).sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime());
});

const recommendationsCount = computed(() => data.value?.recommendations.length ?? 0);
const firstRecommendations = computed(() => recommendations.value.slice(0, 5));

function onOpenDialog() {
  isDialogVisible.value = true;
}
</script>

<template>
  <DashboardWidget :title="`Notifications (${recommendationsCount})`" subtitle="Significant events & quick actions" :loading="isPending">
    <template #title-end>
      <Button
        v-if="recommendationsCount > 0"
        label="View all"
        text
        @click="onOpenDialog"
      />
    </template>

    <DashboardRecommendationsWidgetList :recommendations="firstRecommendations" />

    <Dialog
      v-model:visible="isDialogVisible"
      :header="`Notifications (${recommendationsCount})`"
      modal
    >
      <DashboardRecommendationsWidgetList :recommendations="recommendations" />
    </Dialog>
  </DashboardWidget>
</template>
