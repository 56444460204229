<script setup lang="ts">
import SelectButton from "primevue/selectbutton";
import { computed, ref, watchEffect } from "vue";

import { Store } from "@/graphql";

const props = withDefaults(defineProps<{
  modelValue: Store | null;
  /**
   * If true, the store name is shown
   * othewise only the icon is displayed
   */
  showLabel?: boolean;
  /**
   * If true, the user can select only one store (not All)
   */
  restrictSelection?: boolean;
}>(), {
  showLabel: true,
  restrictSelection: true,
});

const emit = defineEmits<{
  "update:modelValue": [value: Store | null | undefined];
}>();

type StoreSelectOption = { value: Store; label: string; icon: string } | { value: null; label: string };

const options = computed<StoreSelectOption[]>(() =>
  [
    { value: null, label: "All" },
    { value: Store.AppStore, label: "iOS", icon: "pi pi-apple" },
    { value: Store.GooglePlay, label: "Android", icon: "pi pi-android" },
  ].filter(o => (props.restrictSelection ? !!o.value : o)),
);

const selectedValue = ref<StoreSelectOption | null | undefined>();

watchEffect(() => {
  selectedValue.value = options.value.find(o => o.value === props.modelValue);
});

function onChange(selection?: StoreSelectOption | null) {
  selectedValue.value = selection;
  emit("update:modelValue", selection ? selection.value : selection);
}
</script>

<template>
  <SelectButton
    class="store-select-input-component flex"
    data-key="value"
    data-test="store-select-input"
    :model-value="selectedValue"
    :options="options"
    :pt="{ button: { 'data-test': 'store-select-option' } }"
    @update:model-value="onChange"
  >
    <template #option="{ option }">
      <i v-if="option.icon" class="text-lg leading-none" :class="[option.icon, { 'mr-2': showLabel }]" />
      <span v-else>{{ option.label }}</span>
      <span v-if="showLabel">{{ option.label }}</span>
    </template>
  </SelectButton>
</template>
