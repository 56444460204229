<script lang="ts" setup>
import { computed } from "vue";

import { ADS_STATUS_OPTIONS } from "@/constants";

const props = defineProps<{
  value: string[];
}>();

const statusIcons = computed(() =>
  props.value
    .map(option => ADS_STATUS_OPTIONS.find(status => status.value === option)?.icon || null)
    .filter(Boolean),
);
</script>

<template>
  <div v-if="statusIcons.length > 0" class="vd-ad-status-filter-tag-component flex items-center gap-1">
    <i v-for="icon in statusIcons" :key="icon" :class="icon" />
  </div>
</template>
