<script lang="ts" setup>
import { computed } from "vue";

import { STORE_INFOS } from "@/constants";
import { Store } from "@/graphql";

const props = withDefaults(defineProps<{
  store: Store;
  withTooltip?: boolean;
  unstyled?: boolean;
}>(), {
  withTooltip: false,
  unstyled: false,
});

const storeInfo = computed(() => STORE_INFOS[props.store]);

const iconColor = computed(() => {
  if (storeInfo.value == null) {
    return "";
  }
  switch (storeInfo.value.id) {
    case Store.GooglePlay:
      return "text-android";
    case Store.AppStore:
      return "text-ios";
    default:
      return "";
  }
});
</script>

<template>
  <i
    v-tooltip.top="{ value: withTooltip ? storeInfo.label : null }"
    class="store-icon-component inline"
    :class="[
      storeInfo.icon,
      unstyled ? '' : iconColor,
    ]"
  />
</template>
