<script lang="ts" setup>
import isEqual from "lodash.isequal";
import Button from "primevue/button";
import type { ColumnFilterModelType } from "primevue/column";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import { computed, ref, watch } from "vue";

import { type FilterConfig, inputComponentsMapping, tagValueComponentsMapping } from "@/components/business/ListFilters/types";
import { FILTERS_MODES, type FiltersModesKeys } from "@/constants";

const props = withDefaults(defineProps<{
  filter: ColumnFilterModelType;
  configFilter: FilterConfig;
  context?: Record<string, unknown>;
}>(), {
  context: () => ({}),
});

const emit = defineEmits<{
  "update:filter": [filter: ColumnFilterModelType];
  "unpin": [];
  "clear": [];
}>();

const overlayPanelRef = ref();
const dropdownButtonRef = ref();

const selectedMatchMode = ref<FiltersModesKeys>(props.filter.matchMode);
const selectedFilterValue = ref(props.filter.value);

watch(
  () => props.filter,
  (newFilter) => {
    selectedFilterValue.value = newFilter.value;
    if (selectedFilterValue.value != null) {
      selectedMatchMode.value = newFilter.matchMode;
    }
  },
  { deep: true, immediate: true },
);

watch(
  () => selectedMatchMode.value,
  (newMatchMode) => {
    if (selectedFilterValue.value != null) {
      emit("update:filter", {
        value: selectedFilterValue.value,
        matchMode: newMatchMode,
      });
    }
  },
);

watch(
  () => selectedFilterValue.value,
  (newValue) => {
    emit("update:filter", {
      value: newValue,
      matchMode: selectedMatchMode.value,
    });
  },
);

function onToggleOverlay(event: Event) {
  overlayPanelRef.value.toggle(event, dropdownButtonRef.value.$el);
}

function onUpdateMatchMode(matchMode: FiltersModesKeys) {
  selectedMatchMode.value = matchMode;
}

function onUpdateValue(newValue: unknown) {
  selectedFilterValue.value = newValue;
}

const matchModeOption = computed(() => FILTERS_MODES.find(mode => mode.value === selectedMatchMode.value) ?? null,
);
const showMatchMode = computed(() => props.configFilter.matchModes?.length);

function onDelete() {
  emit("unpin");
}

function onClearFilter() {
  emit("clear");
}

const isEmpty = computed(() => isEqual(props.filter.value, props.configFilter.initialValue.value));

defineExpose({
  onToggleOverlay,
});
</script>

<template>
  <div
    v-if="configFilter.isAlwaysDisplayed"
    class="list-filter-component-always-displayed flex"
  >
    <!-- INPUT COMPONENT -->
    <component
      :is="inputComponentsMapping[configFilter.inputComponent]"
      v-if="configFilter.inputComponent"
      class="flex"
      :data-test="`${configFilter.id}-filter`"
      :title="configFilter.title"
      :value="filter.value"
      :match-mode="selectedMatchMode"
      :context="{ ...context, format: configFilter.format }"
      @update:value="(val: unknown) => onUpdateValue(val)"
    />
  </div>

  <div v-else class="list-filter-component flex">
    <!-- TAG COMPONENT -->
    <Button
      ref="dropdownButtonRef"
      class="p-2"
      outlined
      :data-test="`list-filter-button-${configFilter.id}`"
      :severity="isEmpty ? 'secondary' : 'primary'"
      :class="isEmpty ? 'border-surface-300' : 'border-primary'"
      @click="onToggleOverlay"
    >
      <template #default>
        <div class="flex items-center gap-2">
          <!-- TITLE -->
          <span
            :class="{ 'font-semibold': !isEmpty }"
            data-test="list-filter-title"
          >{{ configFilter.title }}</span>

          <!-- MATCHMODE -->
          <span
            v-if="filter.value && showMatchMode"
            data-test="list-filter-match-mode-label"
          >
            {{ matchModeOption?.label }}
          </span>

          <!-- VALUES -->
          <component
            :is="tagValueComponentsMapping[configFilter.tagValueComponent]"
            v-if="filter.value != null && configFilter.tagValueComponent"
            :context="{ ...context, format: configFilter.format }"
            :value="filter.value"
            class="flex"
          />

          <i class="fa-light fa-chevron-down" />
        </div>
      </template>
    </Button>

    <OverlayPanel
      ref="overlayPanelRef"
      :key="configFilter.id"
      class="white-box p-0"
      :pt="{
        content: { class: 'p-4' },
        root: { 'data-test': `list-filter-overlay-${configFilter.id}` },
      }"
    >
      <div class="flex flex-col gap-2">
        <div class="flex items-center justify-between gap-2">
          <div class="flex items-center justify-between gap-2">
            <!-- TITLE -->
            <div class="text-color-secondary text-sm font-semibold">
              {{ configFilter.title }}
            </div>

            <!-- MATCHMODE -->
            <Dropdown
              v-if="showMatchMode"
              :model-value="matchModeOption"
              :options="configFilter.matchModes"
              option-label="label"
              option-value="value"
              class="text-sm"
              :pt="{
                input: { class: 'flex items-center pl-2 pr-1 py-1' },
                trigger: { class: 'pr-2 pl-1 py-1 w-auto' },
              }"
              @update:model-value="onUpdateMatchMode"
            >
              <template #value="slotProps">
                <span class="text-xs">{{ slotProps.value.label }}</span>
              </template>

              <template #dropdownicon>
                <i class="fa-light fa-chevron-down text-xs" />
              </template>
            </Dropdown>
          </div>

          <div class="flex">
            <Button
              data-test="list-filter-clear-button"
              class="w-auto px-2 py-1 text-sm"
              icon="fa-light fa-broom-wide"
              text
              @click="onClearFilter"
            />
            <Button
              data-test="list-filter-delete-button"
              class="w-auto px-2 py-1 text-sm"
              icon="fa-light fa-trash-can"
              text
              @click="onDelete"
            />
          </div>
        </div>

        <!-- INPUT COMPONENT -->
        <component
          :is="inputComponentsMapping[configFilter.inputComponent]"
          v-if="configFilter.inputComponent"
          class="flex"
          :title="configFilter.title"
          :value="filter.value"
          :match-mode="selectedMatchMode"
          :context="{ ...context, format: configFilter.format }"
          @update:value="(val: unknown) => onUpdateValue(val)"
        />
      </div>
    </OverlayPanel>
  </div>
</template>
