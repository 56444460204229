<script lang="ts" setup>
import VdSelectableListBox from "@/components/structures/VdSelectableListBox/VdSelectableListBox.vue";
import type { FormatOption } from "@/constants";
import { AD_FORMAT_OPTIONS } from "@/constants";

withDefaults(defineProps<{
  value: string[];
  context?: { formatOptions?: FormatOption[] };
}>(), {
  context: () => ({
    formatOptions: AD_FORMAT_OPTIONS,
  }),
});

const emit = defineEmits<{
  "update:value": [value: string | string[] | null];
}>();

function onUpdate(value: string | string[] | null) {
  emit("update:value", value);
}
</script>

<template>
  <VdSelectableListBox
    class="format-filter-input-component"
    :value="value"
    :options="context.formatOptions ?? AD_FORMAT_OPTIONS"
    option-label="label"
    option-value="value"
    multiple
    @update:value="onUpdate"
  >
    <template #option="slotProps">
      {{ slotProps.option.label }}
    </template>
  </VdSelectableListBox>
</template>
