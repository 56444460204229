import useNotification from "@/composables/use-notifications";

type useMutationRequestOptions = {
  shouldNotifyError: boolean;
  shouldNotifySuccess: boolean;
  successMessage?: string;
};

/**
 * A wrapper around useMutation that handles error and success notifications
 */
export async function useMutationRequest<U extends { success: boolean }>(
  mutateFn: () => Promise<U | null | undefined>,
  options?: Partial<useMutationRequestOptions>,
): Promise<U | { success: false; __typename: "Error" }> {
  const { notifyError, notifySuccess } = useNotification();

  const { shouldNotifyError, shouldNotifySuccess, successMessage } = {
    shouldNotifyError: true,
    shouldNotifySuccess: true,
    successMessage: "Your operation was successful",
    ...options,
  };

  try {
    const result = await mutateFn();
    if (result == null) {
      throw new Error("No response from server");
    }

    if (result.success && shouldNotifySuccess) {
      notifySuccess({
        detail: successMessage,
        life: 1 * 1000,
      });
    }

    return result;
  }
  catch (e) {
    if (e instanceof Error && shouldNotifyError) {
      notifyError({
        title: "Something went wrong",
        detail: e.message,
      });
    }
    return { success: false, __typename: "Error" };
  }
}
