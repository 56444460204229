import { useMutation, useQueryClient } from "@tanstack/vue-query";

import { graphql, runGraphqlQuery } from "@/graphql";

const mutation = graphql(/* GraphQL */ `
  mutation UseCampaignStatusStopCampaign($campaignId: ID!) {
    stopCampaign(campaignId: $campaignId) {
      success
    }
  }
`);

export function useCampaignStatusStopCampaignMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ campaignId }: { campaignId: string }) {
      const result = await runGraphqlQuery(mutation, { campaignId });
      return result?.stopCampaign;
    },
    onSuccess() {
      void queryClient.invalidateQueries();
    },
  });
}
