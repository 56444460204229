/**
 * extract the full date in format yyyy-mm-dd for the current datetime in the user timezone
 */
export function extractDateFromDatetime(date: Date | null): string {
  if (!date) {
    return "";
  }
  const offset = date.getTimezoneOffset();
  const timezonedDate = new Date(date.getTime() - offset * 60 * 1000);
  return timezonedDate.toISOString().split("T")[0] ?? "";
}
