import { useMutation, useQueryClient } from "@tanstack/vue-query";

import { graphql, runGraphqlQuery, type UseUpdateCampaignTestModeMutationVariables } from "@/graphql";

const mutation = graphql(/* GraphQL */ `
  mutation UseUpdateCampaignTestMode($campaignId: ID!, $value: Boolean!) {
    updateCampaignTestMode(campaignId: $campaignId, value: $value) {
      success
    }
  }
`);

export function useUpdateCampaignTestModeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ campaignId, value }: UseUpdateCampaignTestModeMutationVariables) {
      const result = await runGraphqlQuery(mutation, { campaignId, value });
      return result?.updateCampaignTestMode;
    },
    onSuccess() {
      void queryClient.invalidateQueries();
    },
  });
}
