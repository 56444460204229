import { AgeSet } from "@/graphql";

export function prettifyAgeSet(gender: AgeSet): string {
  const mapping: Record<AgeSet, string> = {
    [AgeSet.Age_13_17]: "13-17",
    [AgeSet.Age_18_24]: "18-24",
    [AgeSet.Age_25_34]: "25-34",
    [AgeSet.Age_35_44]: "35-44",
    [AgeSet.Age_45_54]: "45-54",
    [AgeSet.Age_55More]: "55+",
  };
  return mapping[gender];
}
