export type LanguageOption = {
  label: string;
  code: string;
};

export const ALL_LANGUAGES: LanguageOption[] = [
  { label: "English", code: "en" },
  { label: "Spanish", code: "es" },
  { label: "Portuguese", code: "pt" },
];

export function formatLanguage(code: string): string {
  return ALL_LANGUAGES.find(languageOption => languageOption.code === code)?.label ?? "";
}
