export default {
  notFound: "notFound",
  error: "error",
  login: "login",
  forbidden: "forbidden",
  logout: "logout",
  loginRedirect: "loginRedirect",

  home: "home",

  campaigns: {
    list: "campaignsList",
    details: {
      countries: "campaignDetailsCountries",
      sources: "campaignDetailsSources",
      ads: "campaignDetailsAds",
    },
    ads: {
      newAd: "newPerformanceCampaignAd",
      newNativeAd: "newPerformanceCampaignNativeAd",
    },
    new: "campaignCreation",
    duplicate: "campaignDuplication",
    edit: "campaignEdit",
  },

  brandingCampaigns: {
    new: "brandingCampaignCreation",
    list: "brandingCampaignsList",
    duplicate: "brandingCampaignDuplication",
    edit: "brandingCampaignEdit",
    details: {
      // countries: "brandingCampaignDetailsCountries",
      ads: "brandingCampaignDetailsAds",
    },
    ads: {
      newNativeAd: "newBrandingCampaignNativeAd",
    },
  },

  reporting: "reporting",

  assets: {
    list: "assetsList",
    upload: "assetsUpload",
  },

  apps: {
    list: "appsList",
    details: "appDetails",
    new: "appCreation",
    edit: "appEdit",
  },
};
