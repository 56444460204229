<script lang="ts" setup>
import ProgressBar from "primevue/progressbar";
import { useAttrs } from "vue";

withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;
    loading?: boolean;
  }>(),
  {
    title: "",
    subtitle: "",
  },
);

const attrs = useAttrs();
</script>

<template>
  <div class="vd-page-headline-component flex w-full items-center justify-between gap-4 px-2 leading-9 sm:px-0" v-bind="attrs">
    <div class="vd-page-headline__start-wrapper flex">
      <div class="vd-page-headline__title m-0 text-4xl font-semibold" data-test="vd-page-headline-title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div class="vd-page-headline__subtitle text-color-secondary m-4 mb-0 mr-0">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </div>
      <div class="vd-page-headline__start">
        <slot name="start" />
      </div>
    </div>
    <div class="vd-page-headline__end flex items-center gap-2">
      <slot name="end" />
    </div>
  </div>
  <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.2em; top: -15px" />
</template>
