<script setup lang="ts">
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Button from "primevue/button";
import type { MenuItem } from "primevue/menuitem";
import Skeleton from "primevue/skeleton";
import TieredMenu from "primevue/tieredmenu";
import { computed, onMounted, ref, watch } from "vue";

import useAuthentication from "@/composables/use-authentication";
import type { UserMenuOrganizationFragment } from "@/graphql";

import { useUserMenuQuery } from "./query.graphql";

const { user, logout, login, fetchUserInfo } = useAuthentication();
const { data } = useUserMenuQuery();

onMounted(() => {
  fetchUserInfo();
});

const menuRef = ref();
const selectedOrganizationId = ref<string>();
const selectedOrganization = computed<UserMenuOrganizationFragment | undefined>(() => {
  return data.value?.organizations.find(organization => organization.id === selectedOrganizationId.value);
});

function buildAvatarLetter(name: string) {
  return name[0]?.toUpperCase() ?? "";
}

const organizationAvatar = computed<string>(() => buildAvatarLetter(selectedOrganization.value?.name ?? ""));
const userAvatar = computed<string>(() => buildAvatarLetter(user.value?.email ?? ""));

watch(user, async () => {
  selectedOrganizationId.value = user.value?.organizationId;
}, { immediate: true });

function toggle(event: Event) {
  menuRef.value.toggle(event);
}

const isOrganizationSwitchLoading = ref(false);

function selectOrganization() {
  isOrganizationSwitchLoading.value = true;
  menuRef.value.hide();
}

const organizationItems = computed<MenuItem[]>(() => {
  return (data.value?.organizations ?? []).map(organization => ({
    ...organization,
    type: "organization",
    label: organization.name,
    avatar: buildAvatarLetter(organization.name ?? ""),
    command: async () => {
      await login("/", { organization: organization.id });
      selectOrganization();
    },
  } satisfies MenuItem));
});

const userMenuItems = computed<MenuItem[]>(() => [
  {
    type: "user",
    label: user.value?.email,
    avatar: buildAvatarLetter(user.value?.email ?? ""),
    disabled: true,
  },
  {
    type: "organization",
    avatar: buildAvatarLetter(selectedOrganization.value?.name ?? ""),
    disabled: organizationItems.value.length < 2,
    label: selectedOrganization.value?.name ?? "Select organization",
    ...(organizationItems.value.length > 1 ? { items: organizationItems.value } : {}),
  },
  { separator: true },
  {
    label: "Logout",
    command: () => {
      logout();
    },
  },
]);
</script>

<template>
  <div class="organization-switcher-component">
    <Button
      text
      class="p-0"
      @click="toggle"
    >
      <AvatarGroup :pt="{ root: { class: 'flex-col ml-0' } }">
        <Skeleton v-if="isOrganizationSwitchLoading" size="3rem" />
        <Avatar v-else :label="organizationAvatar" size="large" class="ml-0 border border-solid border-blue-100 bg-blue-50" />
        <Avatar :label="userAvatar" size="large" shape="circle" class="z-50 -mt-4 ml-0" />
      </AvatarGroup>
    </Button>

    <TieredMenu
      id="user_menu"
      ref="menuRef"
      class="w-72"
      :model="userMenuItems"
      popup
    >
      <template #item="{ item, props, hasSubmenu }">
        <a class="align-items-center flex" v-bind="props.action">
          <Avatar v-if="item.type === 'organization'" :label="item.avatar" class="mr-2 border border-solid border-gray-300 bg-blue-50" />
          <Avatar v-else-if="item.type === 'user'" class="mr-2 border border-solid border-gray-300" :label="item.avatar" shape="circle" />
          <span v-else :class="item.icon" class="mr-2" />
          <span>{{ item.label }}</span>
          <i v-if="hasSubmenu" class="pi pi-angle-right ml-auto" />
        </a>
      </template>
    </TieredMenu>
  </div>
</template>
