import { computed, type ComputedRef, reactive, type Ref, ref } from "vue";

import type {
  AppFragment,
  CreateAutomaticAssetMutationResult,
  MetaAppFragment,
} from "@/graphql";

import { createAutomaticAssetMutation } from "./create-automatic-asset-mutation.graphql";
import { type UseCreateAsset, useCreateAsset } from "./use-create-asset";

export type UseCreateAssets = {
  files: ComputedRef<UseCreateAsset<CreateAutomaticAssetMutationResult>[]>;
  createAssets: (params: {
    app: MetaAppFragment | AppFragment;
    language: string;
  }) => Promise<void>;
  deleteFile: (id: string) => void;
  clearFiles: () => void;
  addFile: (file: File) => void;
  isWatcherVisible: Ref<boolean>;
  toggleWatcher: (open: boolean) => void;
};

const assetsById = reactive<
  Record<string, UseCreateAsset<CreateAutomaticAssetMutationResult>>
>({});
const isWatcherVisible = ref(false);
function toggleWatcher(open: boolean): void {
  isWatcherVisible.value = open;
}

export function useCreateAssets(): UseCreateAssets {
  const assets = computed(() => Object.values(assetsById));

  const addFile = (file: File): void => {
    if (assets.value.some(asset => asset.name === file.name)) {
      return;
    }

    const assetFile = useCreateAsset<CreateAutomaticAssetMutationResult>(file);
    assetsById[assetFile.id] = assetFile;
  };

  const deleteFile = (id: string): void => {
    delete assetsById[id];
  };

  const clearFiles = (): void => {
    for (const id of Object.keys(assetsById)) {
      delete assetsById[id];
    }
  };

  const createAssets: UseCreateAssets["createAssets"] = async ({
    app,
    language,
  }) => {
    const uploadableAssets = Object.values(assetsById).filter(
      asset => !asset.error && !asset.isLoading,
    );

    await Promise.all(
      uploadableAssets.map(async (asset) => {
        const createMutationCallback = async (
          presignedId: string,
        ): Promise<CreateAutomaticAssetMutationResult | undefined> =>
          createAutomaticAssetMutation({
            input: {
              metaAppId: app.id,
              language,
              name: asset.name,
              type: asset.type,
              presignedId,
            },
          });

        await asset.create({ app, language }, createMutationCallback);
        if (asset.isSuccess) {
          deleteFile(asset.id);
        }
      }),
    );
  };

  return {
    files: assets,
    createAssets,
    deleteFile,
    clearFiles,
    addFile,
    isWatcherVisible,
    toggleWatcher,
  };
}
