<script setup lang="ts">
import { refDebounced } from "@vueuse/core";
import InputText from "primevue/inputtext";
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    debounce?: number;
  }>(),
  {
    modelValue: "",
    debounce: 500,
  },
);

const emit = defineEmits<{
  "update:model-value": [value: string];
}>();

const search = ref();
const debouncedSearch = refDebounced(search, props.debounce);

watch(debouncedSearch, (value) => {
  emit("update:model-value", value);
});

watch(
  () => props.modelValue,
  (value) => {
    search.value = value;
  },
  { immediate: true },
);
</script>

<template>
  <InputText v-model="search" class="vd-debounced-input-component" />
</template>
