import type { RouteComponent, RouteRecordRaw } from "vue-router";

import links from "./links";

function MetaAppsListPage(): RouteComponent {
  return import("@/pages/apps/MetaAppsListPage/MetaAppsListPage.vue");
}
function MetaAppCreationPage(): RouteComponent {
  return import("@/pages/apps/MetaAppCreationPage/MetaAppCreationPage.vue");
}
function MetaAppEditPage(): RouteComponent {
  return import("@/pages/apps/MetaAppEditPage/MetaAppEditPage.vue");
}

export const appsRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: links.apps.list,
    component: MetaAppsListPage,
    props: true,
    meta: {
      title: "Apps catalog",
    },
  },
  {
    path: "new",
    name: links.apps.new,
    component: MetaAppCreationPage,
    props: true,
    meta: {
      title: "New App",
    },
  },
  {
    path: ":metaAppId",
    redirect: { name: links.apps.edit },
    props: true,
    children: [
      {
        path: "edit",
        component: MetaAppEditPage,
        name: links.apps.edit,
        props: true,
        meta: {
          title: "Edit App",
        },
      },
    ],
  },
];
