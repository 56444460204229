import { type Ref, ref } from "vue";

import { extractVideoInfo } from "@/utils/extract-video-info";

type UseVideoMetadata = {
  duration: Ref<number>;
  width: Ref<number>;
  height: Ref<number>;
};
export function useVideoMetadata(file: File): UseVideoMetadata {
  const duration = ref<number>(-1);
  const width = ref<number>(-1);
  const height = ref<number>(-1);

  if (file.type.includes("video")) {
    void extractVideoInfo(file).then((info) => {
      duration.value = info.duration;
      width.value = info.width;
      height.value = info.height;
    });
  }

  return { duration, width, height };
}
