import { VueQueryPlugin } from "@tanstack/vue-query";
import "@total-typescript/ts-reset";
import LogRocket from "logrocket";
import { createApp, h } from "vue";

import { config } from "@/config";
import { router } from "@/router";
/* primevue */
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import VueApexCharts from "vue3-apexcharts";

import App from "./App.vue";
import { useSentry } from "./composables/use-sentry";
/** App style */
import "./styles/index.scss";
import "./styles/tailwind.scss";

const app = createApp({
  render: () => h(App),
});

app.use(router);

app.use(VueQueryPlugin);

app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);
app.use(VueApexCharts);

if (config.loggers.logRocketAppId != null) {
  LogRocket.init(config.loggers.logRocketAppId, {
    shouldParseXHRBlob: true,
    network: {
      requestSanitizer: (request) => {
        if ((request.body as unknown) instanceof File) {
          delete request.body;
        }
        else if (request.body?.includes("password") === true) {
          request.body = undefined;
        }
        return request;
      },
    },
  });
}

async function initSentry(): Promise<void> {
  const { init } = useSentry();
  await init({ app, router });
}

async function initApp(): Promise<void> {
  if (config.environment === "local") {
    /* eslint-disable-next-line no-console */
    console.info(`RUNNING AGAINST ${config.graphqlEndpointUrl}`);
  }
  app.mount("#app");
}

void initSentry();
void initApp();
