<script setup lang="ts">
import { computed } from "vue";

import { CAMPAIGN_STATUS_ICONS } from "@/constants";
import type { CampaignStatus } from "@/graphql";
import { prettifyCampaignStatus } from "@/utils/prettifiers/prettify-campaign-status";

const props = defineProps<{
  value: CampaignStatus;
  /**
   * Wether to display the status label or not.
   */
  showLabel?: boolean;
  showTooltip?: boolean;
}>();

const icon = computed(() => CAMPAIGN_STATUS_ICONS[props.value]);

const label = computed(() => prettifyCampaignStatus(props.value));
</script>

<template>
  <div class="campaign-status-chip-component flex items-center gap-2">
    <i v-tooltip="{ value: label, disabled: !showTooltip }" :class="[icon]" />
    <span v-if="showLabel" data-test="campaign-status-chip-label">{{ label }}</span>
  </div>
</template>
