<script lang="ts" setup>
import { computed } from "vue";

import type { FilterConfig } from "@/components/business/ListFilters/types";
import { prettifyNumericalValue } from "@/utils/prettifiers/prettify-numerical-value";

const props = defineProps<{
  value: number;
  context?: NonNullable<Pick<FilterConfig, "format">>;
}>();

const numericValue = computed(() => {
  if (props.context == null) {
    return "";
  }
  return prettifyNumericalValue(props.value, {
    format: props.context.format,
    percentMode: props.context.format?.percentMode,
  });
});
</script>

<template>
  <span class="vd-numerical-filter-tag-component">
    {{ numericValue }}
  </span>
</template>
