<script lang="ts" setup>
import { computed } from "vue";

import AppChip from "@/components/business/AppChip/AppChip.vue";
import type { AppFragment } from "@/graphql";

type AppFilterTagContext = {
  appOptions: AppFragment[];
};

const props = defineProps<{
  value: string[];

  context: AppFilterTagContext;
}>();

const icons = computed(() => props.value
  .map(appId => props.context.appOptions.find(app => appId === app.id)?.iconUrl || null)
  .filter(Boolean),
);
</script>

<template>
  <div v-if="icons.length < 5" class="vd-app-filter-tag-component flex items-center gap-1">
    <AppChip v-for="iconUrl in icons" :key="iconUrl" :icon-url="iconUrl" size="xsmall" />
  </div>

  <div v-else class="vd-app-filter-tag-component">
    {{ icons.length }} apps
  </div>
</template>
