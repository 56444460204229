<script lang="ts" setup>
import { computed } from "vue";

import type { AdFormat } from "@/graphql";
import { prettifyAdFormat } from "@/utils/prettifiers/prettify-ad-format";

const props = defineProps<{
  value: AdFormat[];
}>();

const formatValue = computed(() => {
  if (props.value.length === 0) {
    return "";
  }

  return props.value.map(adFormat => prettifyAdFormat(adFormat)).join(", ");
});
</script>

<template>
  <span v-if="formatValue.length > 0" class="vd-format-tag-component">{{ formatValue }}</span>
</template>
