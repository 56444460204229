import { FilterMatchMode, FilterService } from "primevue/api";

import { roundValueWithPrecision } from "../prettifiers/prettify-currency";

export const ROUNDED_EQUALS = "ROUNDED_EQUALS";
export const ROUNDED_NOT_EQUALS = "ROUNDED_NOT_EQUALS";
export const ROUNDED_GREATER_THAN = "ROUNDED_GREATER_THAN";
export const ROUNDED_GREATER_THAN_OR_EQUALS = "ROUNDED_GREATER_THAN_OR_EQUALS";
export const ROUNDED_LESS_THAN = "ROUNDED_LESS_THAN";
export const ROUNDED_LESS_THAN_OR_EQUALS = "ROUNDED_LESS_THAN_OR_EQUALS";

export const ASSET_TYPE_FILTER_RULE = "ASSET_TYPE_FILTER_RULE";

type FiltersKeys =
  | "startsWith"
  | "contains"
  | "notContains"
  | "endsWith"
  | "equals"
  | "notEquals"
  | "in"
  | "lt"
  | "lte"
  | "gt"
  | "gte"
  | "between"
  | "dateIs"
  | "dateIsNot"
  | "dateBefore"
  | "dateAfter"
  | "ROUNDED_EQUALS"
  | "ROUNDED_NOT_EQUALS"
  | "ROUNDED_GREATER_THAN"
  | "ROUNDED_GREATER_THAN_OR_EQUALS"
  | "ROUNDED_LESS_THAN"
  | "ROUNDED_LESS_THAN_OR_EQUALS"
  | string
  | undefined;

type FiltersMetaData = {
  value: unknown;
  matchMode: FiltersKeys;
};

type Filters = Record<string, FiltersMetaData>;

export function filterArray<T>(filters: Filters, data: Array<T>, globalKeys?: string[]): Array<T> {
  let filteredData: T[] = [...data];

  /* Global filter */
  if (filters.global != null && globalKeys) {
    filteredData = FilterService.filter(
      filteredData,
      globalKeys,
      filters.global.value,
      filters.global.matchMode ?? FilterMatchMode.CONTAINS,
    ) as T[];
  }

  /* Other filters */
  const activeFilters = Object.entries(filters)
    .filter(([key]) => key !== "global")
    .map(([key, val]) => ({
      field: key,
      value: val.value,
      matchMode: val.matchMode,
    }));

  for (const filter of activeFilters) {
    filteredData = FilterService.filter(filteredData, [filter.field], filter.value, filter.matchMode as string) as T[];
  }

  return filteredData;
}

/* Constant for new filter method */
export const CONTAINS_ALL = "CONTAINS_ALL";

/*
 * Filtering helpers
 * We are using Math.floor to avoid floating point issues
 * Please note that these two functions work together and should be edited together
 */
const PRECISION_MULTIPLIER = 2;
export function encodeValueForRoundedFilterComparison(value: number): number {
  return roundValueWithPrecision(value, PRECISION_MULTIPLIER) * 10 ** PRECISION_MULTIPLIER;
}
export function decodeValueForReportingDisplay(value: number): number {
  return roundValueWithPrecision(value, PRECISION_MULTIPLIER);
}

FilterService.register(CONTAINS_ALL, (tableValue?: string[] | null, filter?: string[] | null): boolean => {
  if (!tableValue || !filter) {
    return true;
  }

  return filter.every(curValue => tableValue.includes(curValue));
});

FilterService.register(ROUNDED_EQUALS, (tableValue: number, filter: number): boolean => {
  if (!filter) {
    return true;
  }

  return encodeValueForRoundedFilterComparison(filter) === encodeValueForRoundedFilterComparison(tableValue);
});

FilterService.register(ROUNDED_NOT_EQUALS, (tableValue: number, filter: number): boolean => {
  if (!filter) {
    return true;
  }

  return encodeValueForRoundedFilterComparison(filter) !== encodeValueForRoundedFilterComparison(tableValue);
});

FilterService.register(ROUNDED_GREATER_THAN, (tableValue: number, filter: number): boolean => {
  if (!filter) {
    return true;
  }

  return encodeValueForRoundedFilterComparison(tableValue) > encodeValueForRoundedFilterComparison(filter);
});

FilterService.register(ROUNDED_GREATER_THAN_OR_EQUALS, (tableValue: number, filter: number): boolean => {
  if (!filter) {
    return true;
  }

  return encodeValueForRoundedFilterComparison(tableValue) >= encodeValueForRoundedFilterComparison(filter);
});

FilterService.register(ROUNDED_LESS_THAN, (tableValue: number, filter: number): boolean => {
  if (!filter) {
    return true;
  }

  return encodeValueForRoundedFilterComparison(tableValue) < encodeValueForRoundedFilterComparison(filter);
});

FilterService.register(ROUNDED_LESS_THAN_OR_EQUALS, (tableValue: number, filter: number): boolean => {
  if (!filter) {
    return true;
  }

  return encodeValueForRoundedFilterComparison(tableValue) <= encodeValueForRoundedFilterComparison(filter);
});

/* This is a special case, we don't need to filter anything here */
FilterService.register(ASSET_TYPE_FILTER_RULE, (): boolean => true);
