<script lang="ts" setup>
import { format } from "date-fns";
import Button from "primevue/button";
import { computed } from "vue";

import AppIcon from "@/components/business/AppIcon/AppIcon.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import links from "@/router/links";

import type { RecommendationItem } from "../DashboardRecommendationsWidget/types";

const props = defineProps<{
  recommendation: RecommendationItem;
}>();

const recommendationDate = computed(() => {
  const date = props.recommendation.creationDate;

  if (!date) {
    return null;
  }

  return {
    month: format(date, "MMM"),
    day: format(date, "d"),
    time: format(date, "HH:mm"),
  };
});
</script>

<template>
  <div v-if="recommendation.campaign" class="flex items-center justify-between gap-6">
    <div class="flex items-center gap-3">
      <div class="flex w-9 flex-col uppercase">
        <div class="text-center text-[0.625rem] leading-tight tracking-wider text-slate-600">
          {{ recommendationDate?.month }}
        </div>
        <span class="text-center text-xl font-semibold leading-none">{{ recommendationDate?.day }}</span>
        <span class="text-center text-[0.75rem] leading-tight text-slate-600">{{ recommendationDate?.time }}</span>
      </div>

      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <div class="flex flex-1 items-center gap-2">
          <AppIcon
            :url="recommendation.app?.iconUrl ?? ''"
            size="xsmall"
          />

          <span class="font-semibold">{{ recommendation.campaign.name }}</span>
        </div>

        <div class="flex flex-wrap">
          {{ recommendation.adSet?.name }} group has reached the daily budget
        </div>
      </div>
    </div>

    <div class="flex shrink-0 items-center gap-2 text-xs">
      <VdRouterLink
        :to="{
          name: links.campaigns.edit,
          hash: `#${recommendation.adSet?.id}`,
          params: { campaignId: recommendation.campaign.id } }"
      >
        <Button
          label="Update"
          icon="fa-light fa-chevron-right text-xs"
          icon-pos="right"
          outlined
        />
      </VdRouterLink>
    </div>
  </div>
</template>
