import usePersistentColumns from "@/composables/use-persistent-columns";
import { PERFORMANCE_REPORTING_COLUMNS, type TableColumn } from "@/constants";

import { CAMPAIGNS_LIST_PAGE_ID } from "./constants";
import type { CampaignsListRow } from "./types";

/**
 * Columns order customization
 */
export const customizableColumns: TableColumn<CampaignsListRow>[] = [
  { id: "app", label: "App", displayedByDefault: true },
  { id: "campaignType", label: "Type", displayedByDefault: true },
  { id: "deviceType", label: "Device Type" },
  { id: "countries", label: "Countries", displayedByDefault: true },
  { id: "status", label: "Status", displayedByDefault: true },
  {
    id: "hasAutomaticAdsManagement",
    label: "Ads management",
    displayedByDefault: true,
  },
  {
    id: "todaySpendProgression",
    label: "Today's spend",
    displayedByDefault: true,
  },

  {
    id: "totalDailyBudget",
    label: "Daily budget",
    displayedByDefault: true,
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "160px",
  },

  {
    id: "adPlacement",
    label: "Placement",
    displayedByDefault: true,
  },

  ...PERFORMANCE_REPORTING_COLUMNS,
];

export function useColumns() {
  const { selectedColumns } = usePersistentColumns(
    `${CAMPAIGNS_LIST_PAGE_ID}-columns`,
    customizableColumns,
  );

  return {
    selectedColumns,
    customizableColumns,
  };
}
