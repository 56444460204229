<script setup lang="ts">
import Tag from "primevue/tag";
import { computed } from "vue";

import {
  ASSET_SUBTYPE_FILTER_MENU_ITEM,
  ASSET_TYPE_FILTER_MENU_ITEMS,
  type AssetSubTypeFilterMenuItem,
  type AssetTypeFilterMenuItem,
} from "@/constants";
import type { AssetHtmlType } from "@/graphql";
import { AssetType } from "@/graphql";

const props = withDefaults(defineProps<{
  type: AssetType | AssetHtmlType | null;
  mode?: "tag" | "text";
}>(), {
  mode: "tag",
});

const isAssetType = computed<boolean>(() => Object.values(AssetType).includes(props.type as AssetType));
type AssetFilterMenuItemType = AssetTypeFilterMenuItem | AssetSubTypeFilterMenuItem;

const itemsSource = computed<AssetFilterMenuItemType[]>(() =>
  isAssetType.value ? ASSET_TYPE_FILTER_MENU_ITEMS : ASSET_SUBTYPE_FILTER_MENU_ITEM,
);

const icon = computed(() => itemsSource.value.find(tab => tab.key === props.type)?.icon);
const tagBackground = computed(() => itemsSource.value.find(tab => tab.key === props.type)?.color);
const label = computed(() => itemsSource.value.find(tab => tab.key === props.type)?.label);
</script>

<template>
  <span v-if="mode === 'text' && type" class="flex items-center gap-1 text-sm uppercase" :style="{ color: tagBackground }"><i :class="icon" /> {{ label }}</span>
  <Tag v-else-if="type" :value="label" class="asset-type-tag items-center text-white" :icon="icon" :style="{ backgroundColor: tagBackground }" />
</template>
