import { type CreateAutomaticAssetMutationVariables, graphql, runGraphqlQuery } from "@/graphql";

const mutation = graphql(/* GraphQL */ `
  mutation CreateAutomaticAsset($input: CreateAutomaticAssetInput!) {
    createAutomaticAsset(input: $input) {
      success
      nameAlreadyExists
      appDoesNotExist
    }
  }
`);
export async function createAutomaticAssetMutation(variables: CreateAutomaticAssetMutationVariables) {
  const result = await runGraphqlQuery(mutation, variables);
  return result?.createAutomaticAsset;
}
