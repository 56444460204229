<script setup lang="ts">
import type { ApexOptions } from "apexcharts";
import { fromUnixTime, subDays } from "date-fns";
import format from "date-fns/format";
import { type Component, computed, createApp, h, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";

import ChartsEvolutionTag from "@/components/business/Charts/components/ChartsEvolutionTag/ChartsEvolutionTag.vue";
import DashboardWidget from "@/components/business/DashboardWidget/DashboardWidget.vue";
import { prettifyCurrency } from "@/utils/prettifiers/prettify-currency";

import { useDashboardGlobalSpendQuery } from "../../queries/dashboard-global-spend.query.graphql";
import GlobalSpendChartTooltip from "../GlobalSpendChartTooltip/GlobalSpendChartTooltip.vue";

const chart = ref<ApexCharts | null>(null);

const queryParams = ref({
  startDate: format(subDays(new Date(), 7), "yyyy-MM-dd"),
  endDate: format(subDays(new Date(), 1), "yyyy-MM-dd"),
  previousStartDate: format(subDays(new Date(), 14), "yyyy-MM-dd"),
  previousEndDate: format(subDays(new Date(), 8), "yyyy-MM-dd"),
});

const { data, isPending } = useDashboardGlobalSpendQuery(queryParams);

const chartData = computed<ApexAxisChartSeries | null>(() => {
  const documentStyle = getComputedStyle(document.documentElement);
  const primaryColor = documentStyle.getPropertyValue("--primary-color");

  if (data.value?.globalSpend == null) {
    return null;
  }

  const apexAxisConf: ApexAxisChartSeries = [{
    name: "Global Spend",
    color: primaryColor,
    data: data.value.globalSpend.values.map((value) => {
      const date = fromUnixTime(value.date);

      return {
        x: date,
        y: value.value,
        meta: {
          previousValue: data.value?.previousGlobalSpend.values.find((previousValue) => {
            const previousDate = fromUnixTime(previousValue.date).setHours(0, 0, 0, 0);
            const newDate = subDays(date, 7).setHours(0, 0, 0, 0);

            return previousDate === newDate;
          })?.value,
        },
      };
    }).sort((a, b) => a.x.getTime() - b.x.getTime()),
  }] satisfies ApexAxisChartSeries;

  return apexAxisConf;
});

function getHTMLFromComponent(component: Component, props: unknown) {
  const createdComponent = createApp({
    render() {
      return h(component, props);
    },
  });

  const mountedComponent = createdComponent.mount(document.createElement("div"));
  const result = mountedComponent.$el.outerHTML;
  createdComponent.unmount();

  return result;
}

const chartOptions = computed<ApexOptions>(() => {
  return {
    chart: {
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 400,
      },
      redrawOnParentResize: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        right: 28,
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    tooltip: {
      theme: "light",
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const date = new Date(w.config.series[seriesIndex].data[dataPointIndex].x);
        const formattedDate = format(date, "eee, dd MMM yyyy");

        const tagHTML = getHTMLFromComponent(ChartsEvolutionTag, {
          currentValue: series[seriesIndex][dataPointIndex],
          lastValue: w.config.series[seriesIndex].data[dataPointIndex].meta.previousValue,
        });

        return getHTMLFromComponent(GlobalSpendChartTooltip, {
          date: formattedDate,
          value: series[seriesIndex][dataPointIndex],
          tagHTML,
        });
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter(value) {
          return prettifyCurrency(value, { maximumFractionDigits: 0 });
        },
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter(value) {
          if (!value) {
            return "";
          }

          return format(new Date(value), "eee dd");
        },
      },
    },
  } satisfies ApexOptions;
});
</script>

<template>
  <DashboardWidget
    :loading="isPending"
    title="Global Spend"
    subtitle="Last 7 days"
  >
    <template #title-end>
      <div class="flex flex-col">
        <div class="mb-2 flex items-center justify-end gap-3">
          <ChartsEvolutionTag
            v-if="data?.previousGlobalSpend.totalSpend"
            :current-value="data.globalSpend.totalSpend"
            :last-value="data.previousGlobalSpend.totalSpend"
            :loading="isPending"
          />

          <span class="text-2xl font-bold leading-none">{{ data?.globalSpend.totalSpend ? prettifyCurrency(data.globalSpend.totalSpend) : "-" }}</span>
        </div>

        <div class="text-right text-slate-500">
          All campaigns total
        </div>
      </div>
    </template>

    <template #default>
      <div class="flex-1">
        <VueApexCharts
          v-if="chartData"
          ref="chart"
          type="area"
          :height="370"
          :options="chartOptions"
          :series="chartData"
        />
      </div>
    </template>
  </DashboardWidget>
</template>
