<script lang="ts" setup>
import { computed } from "vue";

import AssetTypeTag from "@/components/business/AssetTypeTag/AssetTypeTag.vue";
import type { AssetHtmlType, AssetType } from "@/graphql";

type AssetTypeValue = {
  type: AssetType | null;
  htmlType: AssetHtmlType | null;
};

const props = defineProps<{
  value: AssetTypeValue;
}>();

const value = computed(() => {
  if (props.value.type == null) {
    return null;
  }

  if (props.value.htmlType != null) {
    return props.value.htmlType;
  }

  return props.value.type;
});
</script>

<template>
  <AssetTypeTag :type="value" mode="text" />
</template>
