<script lang="ts" setup>
import { ASSET_STATUS_OPTIONS } from "@/constants";

defineProps<{
  value: string[];
}>();

const iconByStatus = Object.fromEntries(ASSET_STATUS_OPTIONS.map(option => [option.value, option.icon]));
</script>

<template>
  <div v-if="value.length > 0" class="vd-status-filter-tag-component flex items-center gap-1">
    <div v-for="status in value" :key="status">
      <i :class="iconByStatus[status]" />
    </div>
  </div>
</template>
