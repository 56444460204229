import { AdPlacement } from "@/graphql";

type AdPlacementMapping = {
  label: string;
  min: string;
};

export function prettifyAdPlacement(placement: AdPlacement, options?: { minified?: boolean }): string {
  const mapping: Record<AdPlacement, AdPlacementMapping> = {
    [AdPlacement.Native]: { label: "Native", min: "Native" },
    [AdPlacement.Interstitial]: { label: "Interstitial (INT)", min: "INT" },
    [AdPlacement.RewardedVideo]: { label: "Rewarded Video (RV)", min: "RV" },
    [AdPlacement.InterstitialAndRewardedVideo]: { label: "Interstitial & Rewarded Video (INT & RV)", min: "INT & RV" },
  };

  if (options?.minified ?? false) {
    return mapping[placement].min;
  }

  return mapping[placement].label;
}
