<script lang="ts" setup>
import type { ButtonProps } from "primevue/button";
import InputText from "primevue/inputtext";
import { nextTick, onMounted, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: number | null;
    autofocus?: boolean;
    fractionDigits?: number;
    allowEmpty?: boolean;
    prefix?: string;
    size?: ButtonProps["size"] | null;
  }>(),
  {
    fractionDigits: 0,
    prefix: "",
    size: null,
  },
);

const emit = defineEmits<{
  "update:model-value": [value: number | null];
}>();

const currentValue = ref<string>("");
const inputRef = ref();

onMounted(() => {
  if (props.allowEmpty && props.modelValue === null) {
    currentValue.value = "";
    return;
  }
  const valueNumber = props.modelValue ?? 0;
  let result = valueNumber.toString();
  const { fractionDigits } = props;
  result = valueNumber.toFixed(fractionDigits);
  currentValue.value = props.prefix + result;
});

watch(
  () => props.prefix,
  (newPrefix, oldPrefix) => {
    currentValue.value = (newPrefix ?? "") + currentValue.value.replace(oldPrefix ?? "", "");
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue === null) {
      currentValue.value = "";
      return;
    }
    const { fractionDigits } = props;
    const result = newValue.toFixed(fractionDigits);
    currentValue.value = `${props.prefix}${Number.parseFloat(result)}`;
  },
  { immediate: true },
);

async function onFocus() {
  await nextTick();
  if (inputRef.value) {
    inputRef.value.$el.select();
  }
}

function extractFloat(value: string) {
  const str = value.replace(/[^0-9.]/g, "");
  const regex = new RegExp(`[0-9]*\\.?[0-9]{0,${props.fractionDigits}}`, "g");
  const match = str.match(regex);
  return match ? match[0] : null;
}

function updateValue({ prefix, value }: { prefix?: string; value: string | null }) {
  if (value == null || value === "") {
    currentValue.value = "";
    inputRef.value.$el.value = null;
    emit("update:model-value", null);
    return;
  }
  /** handle prefix */
  currentValue.value = (prefix ?? "") + value;

  /** force the update of the input value */
  inputRef.value.$el.value = currentValue.value;

  emit("update:model-value", Number.parseFloat(value));
}

function onUpdate(newValue: string | undefined) {
  const { prefix } = props;
  const strippedValue = newValue?.replace(prefix, "") ?? "";

  /** parse the value stripped from prefix */
  let result: string | null = extractFloat(strippedValue ?? "0");

  if (result === null || result === "") {
    updateValue({ value: props.allowEmpty ? null : "0", prefix: props.allowEmpty ? "" : prefix });
    return;
  }

  /* remove the leading zeros if any */
  result = result.replace(/^0+(?=\d)/, "");

  /** add leading zero if the value starts with a dot */
  if (result.startsWith(".")) {
    result = `0${result}`;
  }

  /** remove the trailing dot if we dont allow decimal */
  if (props.fractionDigits === 0 && result.endsWith(".")) {
    result = result.slice(0, -1);
  }

  /** prevent number overload */
  result = result.slice(0, 12);

  updateValue({ prefix, value: result });
}
</script>

<template>
  <InputText
    ref="inputRef"
    :size="size ?? undefined"
    :model-value="currentValue"
    type="text"
    :autofocus="autofocus"
    @focus="onFocus"
    @update:model-value="onUpdate"
  />
</template>
