import type { MaybeRef } from "vue";
import { unref } from "vue";

import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

type DashboardGlobalSpendVariables = MaybeRef<{
  startDate: string;
  endDate: string;
  previousStartDate: string;
  previousEndDate: string;
}>;

const query = graphql(/* GraphQL */ `
  query DashboardGlobalSpend ($startDate: String!, $endDate: String!, $previousStartDate: String!, $previousEndDate: String!) {
    globalSpend (startDate: $startDate, endDate: $endDate) {
      totalSpend
      values {
        date
        value
      }
    }

    previousGlobalSpend: globalSpend (startDate: $previousStartDate, endDate: $previousEndDate) {
      totalSpend
      values {
        date
        value
      }
    }
  }
`);

function getQueryOptions(variables: DashboardGlobalSpendVariables) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, unref(variables));
    },
  };
}

export function useDashboardGlobalSpendQuery(variables: DashboardGlobalSpendVariables) {
  return buildQuery({
    ...getQueryOptions(variables),
  });
}
