<script setup lang="ts">
import Column from "primevue/column";
import DataTable, { type DataTableSortMeta } from "primevue/datatable";
import { computed, ref } from "vue";

import CampaignStatusChip from "@/components/business/CampaignStatusChip/CampaignStatusChip.vue";
import IsTestModeTag from "@/components/business/IsTestModeTag/IsTestModeTag.vue";
import PlatformIcon from "@/components/business/PlatformIcon/PlatformIcon.vue";
import ReportingTableColumn from "@/components/business/ReportingTableColumn/ReportingTableColumn.vue";
import VdCountryChip from "@/components/structures/VdCountryChip/VdCountryChip.vue";
import VdEmpty from "@/components/structures/VdEmpty/VdEmpty.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import VdTableSkeleton from "@/components/structures/VdTableSkeleton/VdTableSkeleton.vue";
import { useHorizontalScrollBoundaries } from "@/composables/use-horizontal-scroll-boundaries";
import usePermissions from "@/composables/use-permissions";
import useResponsive from "@/composables/use-responsive";
import type { TableColumn } from "@/constants";
import links from "@/router/links";
import type { DataTableColumnBodySlot } from "@/types/helpers";
import { prettifyDeviceType } from "@/utils/prettifiers/prettify-device-type";
import { campaignStatusSort } from "@/utils/sort-campaign-status";

import type { BrandingCampaignsListRow } from "../../types";
import BrandingCampaignDetailsMenu from "../BrandingCampaignDetailsMenu/BrandingCampaignDetailsMenu.vue";

const props = defineProps<{
  rows: BrandingCampaignsListRow[];
  filteredRows: BrandingCampaignsListRow[];
  selectedColumns: TableColumn<BrandingCampaignsListRow>[];
  isLoading: boolean;
  isReportsLoading: boolean;
  isReportsError: boolean;
}>();

const currentColumns = computed<TableColumn<BrandingCampaignsListRow>[]>(() => [
  { id: "name", label: "Name" },
  ...props.selectedColumns,
]);

const table = ref();
const { hasScrollReachedLeft, hasScrollReachedRight } = useHorizontalScrollBoundaries(table);

const { isMobileScreen } = useResponsive();

const { hasCampaignTestModeWritePermission } = usePermissions();

type ColumnData = DataTableColumnBodySlot<BrandingCampaignsListRow>;

const multiSortMeta = ref<DataTableSortMeta[]>([
  { field: campaignStatusSort, order: 1 },
]);
</script>

<template>
  <div class="branding-campaigns-list-table-component">
    <VdTableSkeleton v-if="isLoading" :columns="currentColumns" />

    <slot v-else-if="rows.length === 0" name="empty" />

    <VdEmpty v-else-if="filteredRows.length === 0" title="no data">
      <p class="text-color-secondary m-0">
        No campaign matching your filters.
      </p>
    </VdEmpty>

    <DataTable
      v-else
      ref="table"
      class="campaigns-table p-datatable-sm relative z-0"
      row-hover
      data-key="id"
      :value="filteredRows"
      striped-rows
      removable-sort
      scrollable
      paginator
      :rows="10"
      :rows-per-page-options="[5, 10, 20, 50]"
      sort-mode="multiple"
      :multi-sort-meta="multiSortMeta"
      :pt="{ bodyRow: { 'data-test': 'campaign-row' } }"
    >
      <template #header>
        <slot name="header" />
      </template>

      <Column
        field="name"
        header="Name"
        sortable
        :style="{ 'min-width': '250px' }"
        :frozen="!isMobileScreen"
        class="static-column z-30"
        :class="[{ 'column-left-raised': !hasScrollReachedLeft }]"
      >
        <template #body="{ data }: ColumnData">
          <VdRouterLink
            :to="{
              name: links.brandingCampaigns.details.ads,
              params: { campaignId: data.id },
            }"
            data-test="branding-campaigns-list-campaign-name"
          >
            <div class="flex items-center">
              <span>{{ data.name }}</span>
            </div>
          </VdRouterLink>
          <!-- <span>{{ data.name }}</span> -->
        </template>
      </Column>

      <Column
        v-if="hasCampaignTestModeWritePermission"
        field="isTestMode"
        header="Test"
        sortable
        :style="{ 'min-width': '60px' }"
        class="static-column text-center"
      >
        <template #body="{ data }: ColumnData">
          <IsTestModeTag v-if="data.isTestMode" />
        </template>
      </Column>

      <template v-for="column in selectedColumns" :key="column.id">
        <Column
          v-if="column.id === 'deviceType'"
          :field="column.id"
          :header="column.label"
          sortable
          :style="{ 'min-width': '100px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            {{ prettifyDeviceType(data.deviceType) }}
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'platforms'"
          :field="column.id"
          :header="column.label"
          sortable
          :style="{ 'min-width': '100px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            <div class="space-x-2 text-center">
              <PlatformIcon v-for="platform of data.platforms.toSorted()" :key="platform" class="text-xl" :platform="platform" />
            </div>
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'countries'"
          :field="column.id"
          :header="column.label"
          :style="{ 'min-width': '120px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            <div v-if="data.countries.length > 4">
              {{ data.countries.length }} countries
            </div>
            <div v-else class="align-item-center flex justify-center gap-2">
              <VdCountryChip
                v-for="countryCode of data.countries"
                :key="countryCode"
                :code="countryCode"
                :show-label="false"
                show-tooltip
              />
            </div>
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'status'"
          :field="column.id"
          :header="column.label"
          sortable
          :sort-field="campaignStatusSort"
          :style="{ width: '50px' }"
          class="static-column p-0"
        >
          <template #body="{ data }: ColumnData">
            <div v-if="data.status" class="flex items-center justify-center">
              <CampaignStatusChip :value="data.status" show-tooltip />
            </div>
          </template>
        </Column>

        <Column
          v-else
          :field="column.id"
          sortable
          :style="{ 'min-width': column.minWidth }"
        >
          <template #header>
            <div v-tooltip.top="column.description" style="position: relative">
              {{ column.label }}
            </div>
          </template>
          <template #body="{ data }: ColumnData">
            <ReportingTableColumn
              :format="column.format"
              :value="(data[column.id] as number)"
              :loading="isReportsLoading"
              :in-error="isReportsError"
            />
          </template>
        </Column>
      </template>

      <Column
        :style="{ 'width': '4rem', 'max-width': '4rem', 'padding': 0 }"
        frozen
        align-frozen="right"
        class="static-column"
        :class="[{ 'column-right-raised': !hasScrollReachedRight }]"
      >
        <template #body="{ data }: ColumnData">
          <BrandingCampaignDetailsMenu
            class="py-2"
            :campaign-id="data.id"
            :campaign-status="data.status"
            :is-test-mode-campaign="data.isTestMode"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
