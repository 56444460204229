<script setup lang="ts">
import Button from "primevue/button";
import { useAttrs } from "vue";

const emit = defineEmits<{
  click: [payload: MouseEvent];
}>();

function onClick(event: MouseEvent) {
  emit("click", event);
}

const attrs = useAttrs();
</script>

<template>
  <Button
    icon="fa-solid fa-ellipsis-vertical"
    class="vd-actions-button-component"
    text
    rounded
    severity="primary"
    aria-controls="overlay_menu"
    aria-haspopup
    v-bind="attrs"
    @click="onClick"
  />
</template>
