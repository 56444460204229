import { type ComponentPublicInstance, type Ref, ref, watch } from "vue";

type UseHorizontalScollBoundaries = {
  hasScrollReachedLeft: Ref<boolean>;
  hasScrollReachedRight: Ref<boolean>;
};

export function useHorizontalScrollBoundaries(table: Ref<ComponentPublicInstance>): UseHorizontalScollBoundaries {
  const hasScrollReachedLeft = ref(true);
  const hasScrollReachedRight = ref(false);

  watch(table, (dataTable) => {
    const tableRef = (dataTable?.$el as HTMLElement)?.querySelector(".p-datatable-wrapper") as HTMLElement;
    if (tableRef == null) {
      return;
    }

    let lastKnownScrollPosition = 0;
    let ticking = false;

    const computeBoundaries = (scrollPos: number): void => {
      hasScrollReachedLeft.value = scrollPos === 0;
      hasScrollReachedRight.value = scrollPos + tableRef.offsetWidth === tableRef.scrollWidth;
    };

    computeBoundaries(0);
    tableRef.addEventListener("scroll", () => {
      lastKnownScrollPosition = tableRef.scrollLeft;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          computeBoundaries(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  });

  return {
    hasScrollReachedLeft,
    hasScrollReachedRight,
  };
}
