<script lang="ts" setup>
import VdSelectableListBox from "@/components/structures/VdSelectableListBox/VdSelectableListBox.vue";
import { ASSET_STATUS_OPTIONS } from "@/constants";

defineProps<{
  value: string[] | null;
}>();

const emit = defineEmits<{
  "update:value": [value: string[] | null];
}>();

function onUpdateSelection(value: string | string[] | null) {
  const values = Array.isArray(value) ? value : [value];
  emit("update:value", values?.filter(Boolean) || null);
}
</script>

<template>
  <VdSelectableListBox
    class="asset-status-filter-input-component"
    :value="value"
    :options="ASSET_STATUS_OPTIONS"
    option-label="label"
    option-value="value"
    multiple
    @update:value="onUpdateSelection"
  >
    <template #option="{ option }">
      <div class="flex items-center gap-2">
        <div><i :class="option.icon" /></div>
        <div>
          {{ option.label }}
        </div>
      </div>
    </template>
  </VdSelectableListBox>
</template>
