<script lang="ts" setup>
import { computed } from "vue";

import VdCountryChip from "@/components/structures/VdCountryChip/VdCountryChip.vue";
import VdSelectableListBox from "@/components/structures/VdSelectableListBox/VdSelectableListBox.vue";
import type { CountryOption } from "@/constants/countries";
import { SUPPORTED_COUNTRIES } from "@/constants/countries";

type CountriesFilterTagContext = {
  countriesOptions: string[];
};

const props = defineProps<{
  value?: string | string[];
  context?: CountriesFilterTagContext;
}>();

const emit = defineEmits<{
  "update:value": [value: string | string[] | null];
}>();

function onUpdate(value: string | string[] | null) {
  emit("update:value", value);
}
const filteredCountries = computed<CountryOption[]>(() => {
  if (props.context?.countriesOptions == null) {
    return SUPPORTED_COUNTRIES;
  }

  return SUPPORTED_COUNTRIES.filter(country => props.context?.countriesOptions.includes(country.alpha3));
});
</script>

<template>
  <VdSelectableListBox
    class="countries-filter-input-component"
    data-test="countries-filter-input"
    :value="value"
    :options="filteredCountries"
    option-label="name"
    option-value="alpha3"
    multiple
    filter
    @update:value="onUpdate"
  >
    <template #option="slotProps">
      <VdCountryChip
        class="text-color flex items-center"
        :code="slotProps.option.alpha3"
      />
    </template>
  </VdSelectableListBox>
</template>
