import { AssetHtmlType } from "@/graphql";

export function prettifyAssetHtmlType(redirectionType: AssetHtmlType): string {
  const mapping: Record<AssetHtmlType, string> = {
    [AssetHtmlType.Iec]: "IEC",
    [AssetHtmlType.Playable]: "Playable",
    [AssetHtmlType.Static]: "Static",
  };
  return mapping[redirectionType];
}
