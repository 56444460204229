<script lang="ts" setup>
import StoreSelectInput from "@/components/business/StoreSelectInput/StoreSelectInput.vue";
import type { Store } from "@/graphql";

defineProps<{
  value: Store | null;
}>();

const emit = defineEmits<{
  "update:value": [value: Store | null | undefined];
}>();

function onUpdate(value: Store | null | undefined) {
  emit("update:value", value);
}
</script>

<template>
  <StoreSelectInput
    class="store-filter-input-component"
    :model-value="value"
    :show-label="false"
    restrict-selection
    :pt="{ button: { class: 'flex-1 justify-center' } }"
    @update:model-value="onUpdate"
  />
</template>
