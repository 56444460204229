import { useQuery } from "@tanstack/vue-query";

import { buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query UserMenu {
    organizations {
      ...userMenuOrganization
    }
  }

  fragment userMenuOrganization on Organization {
    id
    name
  }
`);

function getQueryOptions() {
  return {
    queryKey: buildQueryKey(query),
    async queryFn() {
      return runGraphqlQuery(query);
    },
  };
}

export function useUserMenuQuery() {
  return useQuery(getQueryOptions());
}
