<script lang="ts" setup>
import SelectButton from "primevue/selectbutton";

import { IS_TEST_MODE_OPTIONS } from "@/constants";

defineProps<{
  value: boolean | null;
}>();

const emit = defineEmits<{
  "update:value": [value: boolean];
}>();

function onUpdate(value: boolean) {
  emit("update:value", value);
}
</script>

<template>
  <SelectButton
    class="is-test-mode-filter-input-component"
    data-test="is-test-mode-filter-input-component"
    :model-value="value"
    option-label="label"
    option-value="value"
    :options="IS_TEST_MODE_OPTIONS"
    :pt="{ button: { 'class': 'flex-1 justify-center', 'data-test': 'is-test-mode-filter-option' } }"
    @update:model-value="onUpdate"
  />
</template>
