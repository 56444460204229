<script lang="ts" setup>
import Checkbox from "primevue/checkbox";
import Listbox from "primevue/listbox";
import { onMounted, ref } from "vue";

const props = withDefaults(
  defineProps<{
    value?: string | string[] | null;
    options: unknown[];
    optionLabel: string;
    optionValue?: string;
    multiple?: boolean;
    filter?: boolean;
  }>(),
  {
    value: null,
    optionValue: "value",
  },
);

const emit = defineEmits<{
  "update:value": [value: string | string[] | null];
}>();

const listBoxRef = ref();

function onUpdate(value: string | string[] | null) {
  emit("update:value", value);
}

onMounted(() => {
  if (listBoxRef.value && props.filter) {
    listBoxRef.value.$refs.filterInput.focus();
  }
});
</script>

<template>
  <Listbox
    ref="listBoxRef"
    class="vd-selectable-list-box-component flex-col"
    :pt="{
      list: { class: 'max-h-[20rem] w-72 overflow-auto p-1' },
      item: { 'class': 'p-0 rounded-lg mb-1', 'data-test': 'selectable-list-box-option' },
      header: { class: 'p-0' },
      filterInput: { 'class': 'border-surface-0 shadow-none', 'data-test': 'selectable-list-box-filter-input' },
    }"
    :options="options"
    :option-label="optionLabel"
    :option-value="optionValue"
    :model-value="value"
    :multiple="multiple"
    :filter="filter"
    filter-placeholder="Search..."
    @update:model-value="onUpdate"
  >
    <template #option="slotProps">
      <div
        class="flex w-full items-center gap-2 p-2"
        :class="{
          'bg-primary/5': !!value?.includes(slotProps.option[optionValue]),
        }"
      >
        <Checkbox v-if="multiple" :model-value="!!value?.includes(slotProps.option[optionValue])" binary />
        <slot name="option" v-bind="slotProps" :active="!!value?.includes(slotProps.option[optionValue])" />
      </div>
    </template>
  </Listbox>
</template>
