<script lang="ts" setup>
import SelectButton from "primevue/selectbutton";

import { CAMPAIGN_FILTERS_OPTIONS } from "@/constants";
import type { PerformanceCampaignType } from "@/graphql";

defineProps<{
  value: PerformanceCampaignType | null;
}>();

const emit = defineEmits<{
  "update:value": [value: PerformanceCampaignType | null | undefined];
}>();

function onUpdate(value: PerformanceCampaignType | null | undefined) {
  emit("update:value", value);
}
</script>

<template>
  <SelectButton
    class="campaign-type-filter-input flex"
    data-test="campaign-type-filter-input"
    option-value="value"
    :model-value="value"
    :options="Object.values(CAMPAIGN_FILTERS_OPTIONS)"
    :pt="{ button: { 'class': 'campaign-type-filter-option', 'data-test': 'campaign-type-filter-option' } }"
    @update:model-value="onUpdate"
  >
    <template #option="{ option }">
      <div class="flex items-center gap-1">
        <span>{{ option.tagLabel }}</span>
      </div>
    </template>
  </SelectButton>
</template>
