<script lang="ts" setup>
import Dialog from "primevue/dialog";

import ColumnsPicker from "@/components/business/ColumnsPicker/ColumnsPicker.vue";
import type { TableColumn } from "@/constants";

type ColumnInfo = Record<string, unknown>;

defineProps<{
  modelValue: TableColumn<ColumnInfo>[];
  columns: TableColumn<ColumnInfo>[];
  visible?: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [value: boolean];
  "update:model-value": [value: unknown[]];
}>();

function onVisibleToggle(isVisible: boolean) {
  emit("update:visible", isVisible);
}

function onSelectedColumnsChange(columns: unknown[]) {
  emit("update:model-value", columns);
}
</script>

<template>
  <Dialog
    :visible="visible"
    header="Columns customization"
    modal
    :draggable="false"
    :style="{ minWidth: '300px', width: '70vw' }"
    :pt="{ content: { class: 'overflow-auto flex w-full' } }"
    @update:visible="onVisibleToggle"
  >
    <ColumnsPicker :model-value="modelValue" :foo="2" :columns="columns" @update:model-value="onSelectedColumnsChange" />
  </Dialog>
</template>
