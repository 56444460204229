import { AssetRedirectionType } from "@/graphql";

export function prettifyAssetRedirectionType(redirectionType: AssetRedirectionType): string {
  const mapping: Record<AssetRedirectionType, string> = {
    [AssetRedirectionType.Auto]: "auto",
    [AssetRedirectionType.Click]: "click",
    [AssetRedirectionType.Release]: "release",
    [AssetRedirectionType.Cta]: "CTA",
  };
  return mapping[redirectionType];
}
