<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  value: string[];

  context: { groupOptions: { name: string; code: string }[] };
}>();

const groupsValue = computed(() => {
  if (props.value.length > 3) {
    return `${props.value.length} groups`;
  }

  return props.value
    .map((value) => {
      const group = props.context.groupOptions.find(groupOption => groupOption.code === value);
      return group?.name;
    })
    .join(", ");
});
</script>

<template>
  <span v-if="value.length > 0" class="vd-ad-set-filter-tag-component">{{ groupsValue }}</span>
</template>
