<script lang="ts" setup>
import ProgressSpinner from "primevue/progressspinner";
import { computed } from "vue";

const props = withDefaults(defineProps<{
  progress?: number;
  filesLength?: number;
  size?: number;
}>(), {
  progress: 0,
  filesLength: 0,
  size: 46,
});

const emit = defineEmits<{
  toggle: [];
}>();

const RADIUS = props.size / 2;
const strokeDasharray = computed(() => 2 * Math.PI * RADIUS);
const strokeDashoffset = computed(() => strokeDasharray.value * (1 - props.progress / 100));

function onToggleSize() {
  emit("toggle");
}
</script>

<template>
  <div
    class="relative flex cursor-pointer items-center justify-center overflow-hidden rounded-full"
    @click="onToggleSize"
    @keydown="onToggleSize"
  >
    <div
      class="uploads-watcher-overlay absolute inset-0 z-10 flex items-center justify-center gap-1 bg-white text-primary opacity-100 transition-opacity hover:opacity-0"
    >
      <i class="fa fa-upload" />
      <span class="text-xs">{{ filesLength }}</span>
    </div>
    <ProgressSpinner
      class="progress-circle"
      stroke-width="3"
      :pt="{
        root: {
          style: { width: `${size}px`, height: `${size}px` },
          class: 'bg-white rounded-full p-0',
        },
        spinner: {
          style: {
            width: size,
            height: size,
          },
        },
        circle: {
          style: {
            backgroundColor: '#FFF',
            strokeWidth: 3,
            animationDuration: '0s',
            animation: 'p-progress-spinner-color 6s ease-in-out infinite',
            transition: 'stroke-dashoffset 0.5s linear',
            width: size,
            height: size,
            strokeDasharray,
            strokeDashoffset,
          },
          class: 'progress',
        },
      }"
    />
    <div class="text-secondary absolute flex-1 text-xs font-semibold">
      {{ progress.toFixed(0) }}%
    </div>
  </div>
</template>
