<script setup lang="ts">
import Dropdown from "primevue/dropdown";

import { ASSET_HTML_TYPE_OPTIONS } from "@/constants";
import type { AssetHtmlType } from "@/graphql";

defineProps<{
  modelValue: AssetHtmlType | null;
  clearable?: boolean;
}>();

const emit = defineEmits<{
  "update:model-value": [ value: AssetHtmlType];
}>();

function onUpdate(value: AssetHtmlType) {
  emit("update:model-value", value);
}
</script>

<template>
  <Dropdown
    class="asset-html-type-select-input-component flex items-center"
    :model-value="modelValue"
    :show-clear="clearable"
    :options="ASSET_HTML_TYPE_OPTIONS"
    :pt="{ input: 'py-2 pr-6' }"
    option-label="label"
    option-value="value"
    data-key="value"
    placeholder="Select HTML type"
    @update:model-value="onUpdate"
  />
</template>
