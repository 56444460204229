/**
 * Table filtering
 */

import { FilterMatchMode } from "primevue/api";
import { z } from "zod";

import type { SchemaField } from "@/composables/use-url-state";

import {
  ROUNDED_EQUALS,
  ROUNDED_GREATER_THAN,
  ROUNDED_GREATER_THAN_OR_EQUALS,
  ROUNDED_LESS_THAN,
  ROUNDED_LESS_THAN_OR_EQUALS,
  ROUNDED_NOT_EQUALS,
} from "./common";

export const numericalField: SchemaField<{ value: number | null; matchMode: string }> = {
  field: z.object({
    value: z.number().nullable(),
    matchMode: z.enum([
      FilterMatchMode.EQUALS,
      FilterMatchMode.NOT_EQUALS,
      FilterMatchMode.LESS_THAN,
      FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
      FilterMatchMode.GREATER_THAN,
      FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    ]),
  }),
  multi: false,
  encode: obj =>
    obj.value == null ? null : `${obj.matchMode}__${obj.value}`,
  decode: (value) => {
    const [matchMode, val] = value.split("__");
    if (matchMode == null || val == null) {
      return null;
    }
    return { value: Number.parseFloat(val), matchMode };
  },
};

export const numericDefaultValue = {
  value: null,
  matchMode: FilterMatchMode.EQUALS,
};

export const numericalFieldFloat: SchemaField<{ value: number | null; matchMode: string }> = {
  field: z.object({
    value: z.number().nullable(),
    matchMode: z.enum([
      ROUNDED_EQUALS,
      ROUNDED_NOT_EQUALS,
      ROUNDED_LESS_THAN,
      ROUNDED_LESS_THAN_OR_EQUALS,
      ROUNDED_GREATER_THAN,
      ROUNDED_GREATER_THAN_OR_EQUALS,
    ]),
  }),
  multi: false,
  encode: obj =>
    obj.value == null ? null : `${obj.matchMode}__${obj.value}`,
  decode: (value) => {
    const [matchMode, val] = value.split("__");
    if (matchMode == null || val == null) {
      return null;
    }
    return { value: Number.parseFloat(val ?? ""), matchMode };
  },
};

export const numericFloatDefaultValue = {
  value: null,
  matchMode: ROUNDED_EQUALS,
};
