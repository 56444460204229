import { useMediaQuery } from "@vueuse/core";
import type { Ref } from "vue";

type UseResponsive = {
  isMobileScreen: Ref<boolean>;
};

export default function useResponsive(): UseResponsive {
  const isMobileScreen = useMediaQuery("(max-width: 768px)");

  return {
    isMobileScreen,
  };
}
