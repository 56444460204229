<script lang="ts" setup>
import Button from "primevue/button";
import Listbox, { type ListboxChangeEvent } from "primevue/listbox";
import type { MenuItem } from "primevue/menuitem";
import OverlayPanel from "primevue/overlaypanel";
import { computed, nextTick, ref } from "vue";

import type { FilterConfig } from "@/components/business/ListFilters/types";

const props = defineProps<{
  configFilters: Record<string, FilterConfig>;
}>();

const emit = defineEmits<{
  "select-filter": [e: ListboxChangeEvent, value: string];
}>();

const overlayPanelRef = ref();
const listBoxRef = ref();

async function onToggleList(event: Event) {
  overlayPanelRef.value.toggle(event);

  await nextTick();

  if (listBoxRef.value) {
    listBoxRef.value.$refs.filterInput.focus();
  }
}

type GroupedOptions = { label: string; items: MenuItem[] }[];

const groupedOptions = computed<GroupedOptions>(() => {
  const grouped = Object.entries(props.configFilters).reduce<
    Record<string, MenuItem[]>
  >((acc, [key, value]) => {
    const group = value.group || "";

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push({ id: key, label: value.title });

    return acc;
  }, {});

  return Object.entries(grouped).map(([key, value]) => ({
    label: key,
    items: value,
  }));
});

function onSelectFilter(event: ListboxChangeEvent) {
  emit("select-filter", event, event.value);
  overlayPanelRef.value.hide(event);
}
</script>

<template>
  <div class="list-filters-dropdown-component">
    <Button
      class="p-2"
      label="Add filter"
      text
      icon="fa-light fa-plus"
      data-test="list-filters-add-button"
      @click="onToggleList"
    />

    <OverlayPanel
      ref="overlayPanelRef"
      class="white-box overflow-hidden p-0"
      :pt="{ content: { class: 'p-0' } }"
    >
      <Listbox
        ref="listBoxRef"
        class="border-surface-0"
        filter
        option-label="label"
        option-value="id"
        :options="groupedOptions"
        option-group-label="label"
        option-group-children="items"
        filter-placeholder="Search..."
        data-key="id"
        data-test="list-filters-dropdown"
        :pt="{
          list: { class: 'max-h-[20rem] overflow-auto p-2' },
          item: {
            'class': 'rounded-lg px-2',
            'data-test': 'list-filters-option',
          },
          itemGroup: { class: 'p-0' },
          header: { class: 'p-0' },
          filterInput: { class: 'border-surface-0 shadow-none' },
        }"
        @change="onSelectFilter"
      >
        <template #optiongroup="slotProps">
          <div v-if="slotProps.option.label" class="text-400 mt-2 py-2 text-sm">
            <div
              class="flex items-center gap-2 border-b border-surface-300 pb-2"
            >
              {{ slotProps.option.label }}
            </div>
          </div>
        </template>
      </Listbox>
    </OverlayPanel>
  </div>
</template>
