import { v4 as uuid } from "uuid";
import { reactive, readonly, ref } from "vue";

import useUploadToS3 from "@/composables/use-upload-to-s3";
import { useVideoMetadata } from "@/composables/use-video-metadata";
import {
  type AppFragment,
  AssetType,
  type CreateAutomaticAssetMutation,
  type CreateManualAssetMutation,
  type MetaAppFragment,
} from "@/graphql";

type CreateAssetMutationResult =
  | CreateAutomaticAssetMutation["createAutomaticAsset"]
  | CreateManualAssetMutation["createManualAsset"];

export type CreateAssetMutationResultError = Pick<
  CreateAssetMutationResult,
  "appDoesNotExist" | "nameAlreadyExists" | "success"
>;

export type UseCreateAsset<TMutationResult extends CreateAssetMutationResult> =
  Readonly<{
    id: string;
    name: string;
    size: number;
    type: AssetType;
    videoDuration: number;
    uploadProgression: number;
    isLoading: boolean;
    error: CreateAssetMutationResultError | undefined;
    isSuccess: boolean;
    app?: AppFragment | MetaAppFragment;
    file: File;
    language: string;
    create: (
      params: { app: AppFragment | MetaAppFragment; language: string },
      callback: (presignedId: string) => Promise<TMutationResult | undefined>
    ) => Promise<TMutationResult | undefined>;
    setName: (name: string) => void;
    resetError: () => void;
  }>;

export function useCreateAsset<
  TMutationResult extends CreateAssetMutationResult,
>(file: File): UseCreateAsset<TMutationResult> {
  /* prepare refs */
  const error = ref<CreateAssetMutationResultError | undefined>();
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const fileApp = ref<AppFragment | MetaAppFragment>();
  const fileLanguage = ref("");
  const fileName = ref(file.name);

  const resetError = (): void => {
    error.value = undefined;
  };

  const setName = (name: string): void => {
    fileName.value = name;
    if (error.value?.nameAlreadyExists === true) {
      resetError();
    }
  };

  /**
   * Getting video duration
   */
  const { duration: videoDuration } = useVideoMetadata(file);

  /**
   * Uploading to S3
   */
  const { upload: uploadFile, progression: uploadProgression } = useUploadToS3();
  /**
   * Create the file
   */
  const create: UseCreateAsset<TMutationResult>["create"] = async (
    { app, language },
    callback,
  ) => {
    isLoading.value = true;
    fileApp.value = app;
    fileLanguage.value = language;
    let presignedId = null;

    try {
      const item = await uploadFile({ file });
      presignedId = item.presignedId;
    }
    catch (e) {
      error.value = { success: false };
      throw new Error(`Failed to upload file ${String(e)}`);
    }

    try {
      const newAsset = await callback(presignedId);
      if (!newAsset) {
        throw new Error("Failed to create asset");
      }
      if (newAsset.success === false) {
        error.value = {
          appDoesNotExist: newAsset.appDoesNotExist,
          nameAlreadyExists: newAsset.nameAlreadyExists,
          success: false,
        };
      }
      isSuccess.value = !error.value;
      isLoading.value = false;
      return newAsset;
    }
    catch (e) {
      isSuccess.value = false;
      isLoading.value = false;
      throw new Error(`Failed to create asset ${String(e)}`);
    }
  };

  return readonly(
    reactive({
      id: uuid(),
      name: fileName,
      size: file.size,
      type: file.type.includes("video") ? AssetType.Video : AssetType.Html,
      videoDuration,
      uploadProgression,
      isLoading,
      error,
      create,
      isSuccess,
      app: fileApp,
      file,
      language: fileLanguage,
      setName,
      resetError,
    }),
  );
}
