import { useRouteQuery } from "@vueuse/router";
import type { Ref } from "vue";

type UrlStorage<T> = { [K in keyof T]: Ref<string | string[] | null> };

/**
 * Function that will help manage the state of the URL.
 */
export function createUrlStorage<T>(fields: Array<keyof T>) {
  const urlStorage = fields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName as unknown as keyof T]: useRouteQuery(fieldName as unknown as string),
    }),
    {} as UrlStorage<T>,
  );

  function get(): { [K in keyof T]: string | string[] } {
    return fields.reduce((acc, fieldName) => {
      const key = fieldName as unknown as keyof T;
      if (urlStorage[key].value != null) {
        return { ...acc, [key]: urlStorage[key].value };
      }
      return acc;
    }, {} as { [K in keyof T]: string | string[] });
  }

  function set(obj: { [K in keyof T]: string | string[] | null } | null): void {
    if (fields.length === 0) {
      throw new Error("unexpected error");
    }

    for (const fieldName of fields) {
      const key = fieldName as unknown as keyof T;
      if (obj == null) {
        urlStorage[key].value = null;
        continue;
      }

      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        urlStorage[key].value = obj[key] == null || obj[key] === "" ? null : obj[key];
      }
    }
  }

  function deleteParam(paramName: keyof T): void {
    if (urlStorage[paramName] != null) {
      urlStorage[paramName].value = null;
    }
  }

  return {
    get,
    set,
    deleteParam,
  };
}
