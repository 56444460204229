<script lang="ts" setup>
import { computed } from "vue";

import VdCountryChip from "@/components/structures/VdCountryChip/VdCountryChip.vue";

const props = defineProps<{
  value: string | string[];
}>();

const countries = computed(() => (Array.isArray(props.value) ? props.value : [props.value]));
</script>

<template>
  <div v-if="countries.length < 5" class="vd-country-filter-tag-component flex items-center gap-1">
    <div v-for="country in countries" :key="country">
      <VdCountryChip :code="country" :show-label="false" />
    </div>
  </div>

  <div v-else class="vd-country-filter-tag-component">
    {{ countries.length }} countries
  </div>
</template>
