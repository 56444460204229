<script lang="ts" setup>
import SelectButton from "primevue/selectbutton";

import { DEVICE_TYPE_OPTIONS } from "@/constants";
import type { DeviceType } from "@/graphql";

defineProps<{
  value: DeviceType | null;
}>();

const emit = defineEmits<{
  "update:value": [value: DeviceType | null | undefined];
}>();

function onUpdate(value: DeviceType | null | undefined) {
  emit("update:value", value);
}
</script>

<template>
  <SelectButton
    class="device-type-filter-input-component"
    data-test="device-type-filter-input-component"
    :model-value="value"
    option-label="label"
    option-value="value"
    :options="DEVICE_TYPE_OPTIONS"
    :pt="{
      button: {
        'class': 'flex-1 justify-center',
        'data-test': 'device-type-filter-option',
      },
    }"
    @update:model-value="onUpdate"
  />
</template>
