<script lang="ts" setup>
import { computed } from "vue";

import { CAMPAIGN_FILTERS_OPTIONS } from "@/constants";
import type { PerformanceCampaignType } from "@/graphql";

const props = defineProps<{
  value: PerformanceCampaignType;
}>();

const campaignType = computed(() => (props.value ? CAMPAIGN_FILTERS_OPTIONS[props.value] : null));
</script>

<template>
  <span class="vd-device-type-filter-tag-component">{{ campaignType?.tagLabel }}</span>
</template>
