import type { App } from "vue";
import type { Router } from "vue-router";

import { config } from "@/config";

let sentryInstance: unknown = null;

export function useSentry() {
  async function getSentryInstance() {
    if (config.loggers.sentryDns != null) {
      const Sentry = await import("@sentry/vue");
      if (sentryInstance != null) {
        return sentryInstance as typeof Sentry;
      }
      sentryInstance = Sentry;
      return Sentry;
    }
    return null;
  }

  async function init({ app, router }: { app: App; router: Router }): Promise<void> {
    const Sentry = await getSentryInstance();
    if (Sentry) {
      Sentry.init({
        app,
        environment: config.environment,
        dsn: config.loggers.sentryDns,
        replaysSessionSampleRate: 1.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        tracePropagationTargets: [config.baseUrl, /^\//],
      });
    }
  }

  async function setUser(email: string) {
    const Sentry = await getSentryInstance();
    Sentry?.setUser({ email });
  }

  async function log(error: Error, hint?: unknown) {
    try {
      const Sentry = await getSentryInstance();
      Sentry?.captureException(error, { data: hint });
    }
    catch (e) {
      console.error("Error logging to Sentry", e);
    }
  }

  return {
    log,
    init,
    setUser,
  };
}
