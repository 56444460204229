import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouterOptions,
} from "vue-router";

import useAuthentication from "@/composables/use-authentication";
import ForbiddenPage from "@/pages/Forbidden/ForbiddenPage.vue";
import HomePage from "@/pages/Home/HomePage.vue";
import LoginPage from "@/pages/Login/LoginPage.vue";
import LogoutPage from "@/pages/Logout/LogoutPage.vue";
import NotFoundPage from "@/pages/NotFound/NotFoundPage.vue";
import ReportingPage from "@/pages/Reporting/ReportingPage.vue";
import RootPage from "@/pages/Root/RootPage.vue";

import { appsRoutes } from "./apps";
import { assetsRoutes } from "./assets";
import { brandingCampaignRoutes } from "./branding-campaigns";
import { campaignRoutes } from "./campaigns";
import links from "./links";

export const routerConfig: RouterOptions = {
  history: createWebHistory("/"),
  routes: [
    {
      path: "/",
      component: RootPage,
      props: true,
      children: [
        {
          path: "",
          name: links.home,
          component: HomePage,
        },
      ],
    },
    {
      path: "/",
      component: RootPage,
      children: [
        {
          path: "campaigns",
          props: true,
          children: campaignRoutes,
        },
        {
          path: "branding-campaigns",
          props: true,
          children: brandingCampaignRoutes,
        },

        {
          path: "reporting",
          component: ReportingPage,
          name: links.reporting,
          props: true,
          meta: {
            title: "Reporting",
          },
        },
        {
          path: "assets",
          props: true,
          children: assetsRoutes,
        },
        {
          path: "apps",
          props: true,
          children: appsRoutes,
        },
      ],
    },

    {
      path: "/login",
      name: links.login,
      component: LoginPage,
      props: true,
      meta: {
        title: "Login",
        noAuthRequired: true,
        noAuthOnly: true,
      },
    },
    {
      path: "/forbidden",
      name: links.forbidden,
      component: ForbiddenPage,
      props: true,
      meta: {
        title: "Forbidden",
        noAuthRequired: true,
      },
    },
    {
      path: "/logout",
      component: LogoutPage,
      meta: {
        noAuthRequired: true,
      },
    },
    {
      path: "/implicit/callback",
      name: links.loginRedirect,
      component: NotFoundPage,
    },
    {
      path: "",
      redirect: { name: links.home },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFoundPage,
      meta: { noAuthRequired: true },
    },
  ],
};

export const router = createRouter(routerConfig);

let needWindowReferesh = false;

/**
 * Reload the window if it has been flagged to
 */
router.beforeEach(
  (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (needWindowReferesh === true) {
      window.location.replace(to.fullPath);
    }
    return next();
  },
);

router.beforeEach(async (to: RouteLocationNormalized) => {
  const { routeAuthGuard } = useAuthentication();
  const authGuard = routeAuthGuard({
    router,
    loginRouteName: links.login,
    forbiddenRouteName: links.forbidden,
    authRedirectRouteName: links.loginRedirect,
  });
  return authGuard(to);
});

router.beforeEach(
  (to: RouteLocationNormalized, _, next: NavigationGuardNext) => {
    const { isAuthenticated } = useAuthentication();
    const nextPageNoAuthOnly = (to.meta?.noAuthOnly as boolean | null) ?? false;
    if (nextPageNoAuthOnly && isAuthenticated.value) {
      return next({ name: links.home });
    }

    return next();
  },
);

/**
 * Scroll the window to top between each pages...
 * ...unless preventScrollTop is true in the route meta infos.
 */
router.afterEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    const previousPagePreventScrollTop = (from?.meta?.preventScrollTop as boolean | null) ?? false;
    const nextPagePreventScrollTop = (to?.meta?.preventScrollTop as boolean | null) ?? false;
    if (!(nextPagePreventScrollTop && previousPagePreventScrollTop)) {
      const routerContainer = document.getElementById("router-container");
      routerContainer?.scrollTo({ top: 0 });
    }
  },
);

/**
 * Set the document title from route's meta information.
 */
router.beforeEach(
  (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const title = "ADN Campaign Manager";
    const nextRouteTitle = to?.meta?.title as string | null;
    document.title = nextRouteTitle == null ? title : `${title} | ${nextRouteTitle}`;
    return next();
  },
);

/**
 * This constant is generated by the vite-plugin-version-mark plugin
 */

declare let __ADN_CAMPAIGN_MANAGER_FRONTEND_APP_VERSION__: string;

/**
 * Reload the window if the frontend version and the backend version don't match.
 */
router.beforeEach(
  (
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    // eslint-disable-next-line no-console
    console.log(`ADN_CAMPAIGN_MANAGER_FRONTEND_APP_VERSION : ${__ADN_CAMPAIGN_MANAGER_FRONTEND_APP_VERSION__}`);

    void fetch(`/__version?${Date.now()}`, { cache: "no-cache" }).then(
      async (data) => {
        const version = await data.text();
        const backendVersion = version.slice(0, 7);
        const frontendVersion = __ADN_CAMPAIGN_MANAGER_FRONTEND_APP_VERSION__.slice(0, 7);
        if (import.meta.env.PROD && backendVersion !== frontendVersion) {
          console.error(
            `backend version (${backendVersion}) != app version (${frontendVersion})`,
          );
          /**
           * When the app is deployed sometime the backend is not ready yet.
           * This can cause the app to endlessly reload it self while waiting for the backend to have the same version.
           * To prevent this, we wait 10 seconds before flagging for a reload. This will give the time necessary for
           * the backend to fully deploy.
           */
          setTimeout(() => {
            needWindowReferesh = true;
          }, 10000);
        }
      },
    );

    return next();
  },
);
