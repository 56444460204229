export type VideoInfo = {
  name: string;
  lastModified: number;
  mimeType: string;
  duration: number;
  size: number;
  width: number;
  height: number;
};

export async function extractVideoInfo(file: File): Promise<VideoInfo> {
  const isVideo = file.type.includes("video");
  if (!isVideo) {
    throw new Error("The file is not a video");
  }

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event: Event) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = (event.target as FileReader)?.result as string;
      video.onloadedmetadata = () => {
        resolve({
          name: file.name,
          lastModified: file.lastModified,
          mimeType: file.type,
          duration: video.duration,
          size: file.size,
          width: video.videoWidth,
          height: video.videoHeight,
        } satisfies VideoInfo);
      };
    };
    reader.readAsDataURL(file);
  });
}
